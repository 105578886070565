import { CMSComponentOtherProperties, CMSParagraphComponentContent } from 'types/CMS';
import { Text } from 'ui/Text/Text';

/**
 * The CMS paragraph component template.
 *
 * @author janssco
 *
 * @param {string} locale - The current locale.
 * @param {string} content - The paragraph content.
 * @constructor
 *
 */

interface CMSParagraphComponentProps {
  content?: CMSParagraphComponentContent;
  locale: string;
  otherProperties?: CMSComponentOtherProperties;
}

function CMSParagraphComponent({ content, locale, otherProperties }: CMSParagraphComponentProps) {
  const paragraphContent = otherProperties?.find(({ key }) => key === 'content');

  if (content) {
    const localizedEntry = content?.entry?.find((entry) => entry.key === locale);

    if (localizedEntry) {
      const localizedContent = localizedEntry?.value;

      if (localizedContent) {
        return <Text html={localizedContent} />;
      }
    }
  }

  if (paragraphContent) {
    const paragraphContentValue = paragraphContent?.value?.value;

    return <Text html={paragraphContentValue} />;
  }

  return null;
}

export default CMSParagraphComponent;
