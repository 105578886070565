import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { EmblaOptionsType } from 'embla-carousel';
import { CMSMasonryContentBlock } from 'types/CMS';
import CMSMasonryContentBlockComponent from '../CMSMasonryContentBlock/CMSMasonryContentBlockComponent';

interface CMSContentBlockSliderProps {
  contentBlocks: CMSMasonryContentBlock[];
}

const CMSContentBlockSlider = ({ contentBlocks }: CMSContentBlockSliderProps) => {
  if (contentBlocks && contentBlocks.length > 0) {
    const showArrows = { [BREAKPOINTS.XS]: true };

    const options: EmblaOptionsType = { loop: true };

    const slidesToShow: SlidesObject = {
      [BREAKPOINTS.XS]: 1,
    };

    return (
      <div className="c-masonry__item c-masonry--1x2 content-block-slider">
        <div className="o-box o-box--border">
          <SliderEmbla showArrows={showArrows} slidesToShow={slidesToShow} options={options}>
            {contentBlocks.map((contentBlock) => {
              if (contentBlock && contentBlock.template) {
                return (
                  <CMSMasonryContentBlockComponent
                    {...contentBlock}
                    key={contentBlock.template}
                    template={contentBlock.template}
                  />
                );
              }
              return null;
            })}
          </SliderEmbla>
        </div>
      </div>
    );
  }
  return null;
};

export default CMSContentBlockSlider;
