import CMSInfoContainerDataFullTemplate from './Info/CMSInfoContainerDataFullTemplate';
import CMSInfoContainerDataMinimalTemplate from './Minimal/CMSInfoContainerDataMinimalTemplate';
import CMSInfoContainerDataGiftCardTemplate from './GiftCard/CMSInfoContainerDataGiftCardTemplate';

const templates = {
  Full: CMSInfoContainerDataFullTemplate,
  SERVICES: CMSInfoContainerDataFullTemplate,
  Minimal: CMSInfoContainerDataMinimalTemplate,
  GIFTCARD: CMSInfoContainerDataGiftCardTemplate,
};

export default templates;
