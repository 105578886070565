import styled, { css } from 'styled-components';
import { breakpointUp, color, specific } from 'theme';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import { spacer } from '../../../../../theme/functions';

export const ComponentBannerContainer = styled.div`
  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      overflow-x: hidden;
    `
  )};

  .part {
    position: relative;

    .checklist {
      padding-left: ${spacer(100)};
    }

    &::after {
      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
        `
      )};
    }

    &.left-part {
      color: ${specific('cms', 'banner-left-color')};
      background-color: ${specific('cms', 'banner-left-background')};

      &::after {
        background-color: ${specific('cms', 'banner-left-background')};

        ${breakpointUp(
          BREAKPOINTS.MD,
          css`
            right: 100%;
          `
        )};
      }
    }

    &.right-part {
      background-color: ${color('secondary-transparent-300')};

      &::after {
        background-color: ${color('secondary-transparent-300')};

        ${breakpointUp(
          BREAKPOINTS.MD,
          css`
            left: 100%;
          `
        )};
      }
    }
  }
`;
