import Button from 'components/Button/Button';
import CategoryNavigationOverlay from 'components/General/CategoryNavigationOverlay/CategoryNavigationOverlay';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { ICON_NAMES } from 'constants/icon';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import useWindowSize from 'hooks/useWindowSize';
import { ReactNode, memo, useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import type {
  CMSBannerCarouselComponentProps,
  CMSBannerCarouselComponent as CMSBannerCarouselComponentType,
} from 'types/CMS';
import { Category } from 'types/Category';
import {
  StyledCMSBannerCarouselComponent,
  StyledLinkWrapper,
  StyledNavigationOverlay,
  StyledSliderEmbla,
  StyledSliderNavigation,
  StyledSliderWrapper,
} from './CMSBannerCarouselComponent.styled';

const WrappedLinkBanner = ({ children, item }: { children: ReactNode; item: CMSBannerCarouselComponentType }) => {
  if (!item?.button?.url) return children;

  return <Link url={item.button.url}>{children}</Link>;
};

const CMSBannerCarouselComponent = ({ components }: CMSBannerCarouselComponentProps) => {
  const { formatMessage } = useIntl();
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeCategory, setActiveCategory] = useState<Category>();
  const sliderRef = useRef<EmblaCarouselType>(null);
  const { isTablet } = useWindowSize();

  const onSlideChange = (newIndex: number) => {
    setActiveSlide(newIndex);
  };

  const goToSlide = (index: number) => {
    if (sliderRef?.current) {
      sliderRef.current.scrollTo(index);
    }
  };

  const imageComponents = useMemo(
    () =>
      components?.filter(
        ({ button, mediumImage, mobileImage, wideImage }) => button && mobileImage && mediumImage && wideImage,
      ),
    [components],
  );

  const options: EmblaOptionsType = {
    loop: true,
  };
  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1,
    [BREAKPOINTS.SM]: 1,
    [BREAKPOINTS.MD]: 1,
    [BREAKPOINTS.LG]: 1,
    [BREAKPOINTS.XL]: 1,
    [BREAKPOINTS.XXL]: 1,
  };

  if (!imageComponents?.length) return null;

  return (
    <StyledCMSBannerCarouselComponent>
      <div className="grid grid-cols-4 gap-6">
        <StyledNavigationOverlay>
          <CategoryNavigationOverlay
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            slogan={formatMessage({
              id: 'header_main_nav_categories_slogan',
            })}
          />
        </StyledNavigationOverlay>

        <div className="col-span-full xl:col-span-3">
          <StyledSliderWrapper>
            <StyledSliderEmbla
              showArrows={{ [BREAKPOINTS.MD]: true }}
              ref={sliderRef}
              options={options}
              slidesToShow={slidesToShow}
              autoplay
              showArrowsOnHover={true}
              arrowPosition={ARROW_POSITIONS.CENTER}
              onScrollCallback={onSlideChange}
              showDots={{ [BREAKPOINTS.XS]: true, [BREAKPOINTS.MD]: false }}
            >
              {imageComponents.map((item, index) => {
                const bannerImage = isTablet ? item.mobileImage : item.wideImage;

                return (
                  <WrappedLinkBanner key={item.bannerTitle} item={item}>
                    <Image
                      alt={item.button.text}
                      layout="fill"
                      objectFit="cover"
                      objectPosition="top left"
                      priority={index === 0}
                      sizes={{
                        default: 700,
                        md: 1600,
                        sm: 1000,
                      }}
                      src={bannerImage}
                    />
                    {item?.title && item?.subtitle && (
                      <>
                        <Typography>{item.title}</Typography>
                        <Typography>{item.subtitle}</Typography>
                      </>
                    )}
                    {item.showButton && item.button?.text && item.button?.url && (
                      <StyledLinkWrapper url={item.button.url}>
                        <Button icon={ICON_NAMES.ANGLE_RIGHT}>{item.button.text}</Button>
                      </StyledLinkWrapper>
                    )}
                  </WrappedLinkBanner>
                );
              })}
            </StyledSliderEmbla>

            <StyledSliderNavigation>
              {imageComponents.map((item, index) => {
                if ((item.button || item.bannerTitle) && item.mobileImage && item.mediumImage && item.wideImage) {
                  const title = item.bannerTitle || item.button.text;
                  const isActive = index === activeSlide;

                  return (
                    <div key={item?.wideImage} onClick={() => goToSlide(index)} role="button" tabIndex={0}>
                      <Typography color="" fontSize={88} fontWeight={isActive ? 'bold' : 'normal'}>
                        {title}
                      </Typography>
                    </div>
                  );
                }
                return null;
              })}
            </StyledSliderNavigation>
          </StyledSliderWrapper>
        </div>
      </div>
    </StyledCMSBannerCarouselComponent>
  );
};

export default memo(CMSBannerCarouselComponent, isEqual);
