import { HTMLAttributes } from 'react';

const ArrowRightIcon = (props: HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="7px"
    height="13px"
    viewBox="0 -1 7 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M4.03404477,8.39030974 L8.56764858,3.76990598 C8.8300548,3.50247576 8.78543417,3.10647574 8.46798562,2.88541518 C8.15053706,2.66435462 7.6804719,2.70194466 7.41806567,2.96937489 L3.47649463,7.01147381 L-0.416761386,2.97354865 C-0.676431368,2.70422961 -1.14609596,2.66323973 -1.4657866,2.88199518 C-1.78547724,3.10075063 -1.83413359,3.49641319 -1.57446361,3.76573222 L2.8804022,8.38613598 C3.17719502,8.6939573 3.73412454,8.6959722 4.03404477,8.39030974 Z"
      id="Shape-Copy-2"
      stroke="#D7D7D7"
      strokeWidth="1"
      fill="#D7D7D7"
      fillRule="evenodd"
      transform="translate(3.498620, 5.679832) rotate(-90.000000) translate(-3.498620, -5.679832) "
    />
  </svg>
);

export default ArrowRightIcon;
