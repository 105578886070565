import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import { ProductCard } from 'components/Product/ProductCard';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';
import { EmblaOptionsType } from 'embla-carousel';
import useProductCard from 'hooks/useProductCard';
import breakpointValues from 'theme/variables/breakpoints';
import { CMSContentBlock } from 'types/CMS';
import { Product } from 'types/Product';
import { StyledMasonry } from '../../../CMSMasonryComponents.styled';

interface CMSProductTilesDealsTemplateProps {
  centered?: boolean;
  contentBlock?: CMSContentBlock;
  products?: Product[];
}

const CMSProductTilesDealsTemplate = ({
  centered = false,
  contentBlock,
  products,
}: CMSProductTilesDealsTemplateProps) => {
  const showArrows = { [BREAKPOINTS.LG]: true };

  const options: EmblaOptionsType = {
    breakpoints: {
      [`(min-width: ${breakpointValues.md}px)`]: { slidesToScroll: 2 },
      [`(min-width: ${breakpointValues.lg}px)`]: { slidesToScroll: 3 },
      [`(min-width: ${breakpointValues.xl}px)`]: { slidesToScroll: 4 },
    },
  };

  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.7,

    [BREAKPOINTS.MD]: 2.5,
    [BREAKPOINTS.LG]: 3,
    [BREAKPOINTS.XL]: 4,
  };
  const { mapProductCardProps } = useProductCard();

  if (products?.length) {
    return (
      <StyledMasonry className="container">
        <section className={`folder-section webexclusive ${centered ? 'centered' : ''}`}>
          <div className="grid md:grid-cols-3">
            {contentBlock && (
              <div className="grid-item">
                <div className="grid-item-content intro_with_button">
                  <div className="content-wrap">
                    {contentBlock.title && (
                      <Typography tag="h3" type="h2">
                        {contentBlock.title}
                      </Typography>
                    )}

                    {!!contentBlock?.text?.length &&
                      contentBlock?.text?.map((line, index) => (
                        <div key={index} className="f-bold mt-md" dangerouslySetInnerHTML={{ __html: line }} />
                      ))}
                    {contentBlock.button && (
                      <div>
                        <Link url={contentBlock.button.url}>
                          <Button color="secondary">{contentBlock.button.text}</Button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="md:col-span-2">
              <div className="folder-slider">
                <SliderEmbla slidesToShow={slidesToShow} options={options} showArrows={showArrows}>
                  {products.map((product) => {
                    const productCardProps = mapProductCardProps({ product });
                    return <ProductCard key={product.code} className="grid-item one-by-one" {...productCardProps} />;
                  })}
                </SliderEmbla>
              </div>
            </div>
          </div>
        </section>
      </StyledMasonry>
    );
  }
  return null;
};

export default CMSProductTilesDealsTemplate;
