import Typography from '../../../../../../../../Typography/Typography';
import FaqItemOverview from '../InfoItemOverview/InfoItemOverview';
import { FaqGroupOverviewList } from './InfoGroupOverview.styled';
import { FaqGroup } from 'types/Faq';

interface InfoGroupOverviewProps {
  group: FaqGroup;
}

const InfoGroupOverview = ({ group }: InfoGroupOverviewProps) => (
  <FaqGroupOverviewList>
    <Typography tag="li" fontWeight="bold">
      {group.title}
    </Typography>

    {group?.faqItems?.map((faqItem) => (
      <FaqItemOverview key={faqItem.url} item={faqItem} />
    ))}
  </FaqGroupOverviewList>
);

export default InfoGroupOverview;
