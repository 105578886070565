import React, { ReactNode } from 'react';

interface NavListProps {
  children?: ReactNode;
  className?: string;
}

const NavList = ({ children, className }: NavListProps) => {
  return <ul className={className}>{children}</ul>;
};

export default NavList;
