import { CMSCategoryTile } from 'types/CMS';
import CategoryTile from '../../../../Category/CategoryTile';
import Link from '../../../../Link/Link';

type CMSCategoryTileDataProps = { cmsComponent: CMSCategoryTile };

const CMSCategoryTileData = ({ cmsComponent }: CMSCategoryTileDataProps) => {
  const image = cmsComponent?.image;
  const link = cmsComponent?.link;
  const name = cmsComponent?.name;

  return (
    <div className="c-masonry__item c-masonry--1x1 category">
      <div className="o-box centered">
        <Link url={link ?? '/'}>
          {image && <CategoryTile height={100} layout="responsive" name={name} src={image?.url} width={100} />}
        </Link>
      </div>
    </div>
  );
};

export default CMSCategoryTileData;
