import { useWebConfig } from 'features/configuration/queries';
import { CMSButton } from 'types/CMS';
import { STATIC_IMAGES } from '../../../../../../../constants/media';
import Image from '../../../../../../Image/Image';
import Link from '../../../../../../Link/Link';

type CMSContentBlockDemoDayTemplateProps = { button?: CMSButton; text?: string[]; title?: string };

/**
 * @param {object} button - The button object containing the text and url.
 * @param {Array} text - The checkmark list items.
 * @param {string} title - The title of the component.
 * @constructor
 */
const CMSContentBlockDemoDayTemplate = ({ button, text = [], title }: CMSContentBlockDemoDayTemplateProps) => {
  const { data: webConfig } = useWebConfig();
  const staticImages = webConfig?.staticImages;

  const demoDaysImg = staticImages?.find((img) => img.code === STATIC_IMAGES.DEMODAYS);

  return (
    <div className="demo-days pt-l pl-xl mb-20">
      <div className="grid md:grid-cols-2">
        <div className="intro-text">
          <span className="demo-days-title mb-s">
            <h2 className="h-style-2 radikal">{title}</h2>
          </span>

          {text && text.map((line, index) => <p dangerouslySetInnerHTML={{ __html: line }} key={index} />)}

          {button && !button.isExternal && <Link url={button.url}>{button.text}</Link>}
          {button && button.isExternal && (
            <Link newTab url={button.url}>
              {button.text}
            </Link>
          )}
        </div>
        {!!demoDaysImg?.url && (
          <div className="intro-image pt-l">
            <Image alt="Demo days" className="demodays-intro" src={demoDaysImg.url} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CMSContentBlockDemoDayTemplate;
