import { useIntl } from 'react-intl';
import { CMSButton } from 'types/CMS';
import Button from '../../../../../../Button/Button';
import EcoChequeIcon from '../../../../../../Icon/EcoChequeIcon/EcoChequeIcon';
import Link from '../../../../../../Link/Link';

type CMSEcoChequeTemplateProps = { button?: CMSButton; index: number; title?: string };

const CMSEcoChequeTemplate = ({ button, index, title }: CMSEcoChequeTemplateProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={`${index % 2 === 0 ? 'left-part' : 'right-part'} part title-with-button eco`}>
      <div className="banner-content">
        <h3>
          <span>{title}</span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: formatMessage({
                id: 'eco_cheque_component_sub_title',
              }),
            }}
          />
        </h3>

        {button?.url && (
          <Link className="eco-link" newTab={button?.isExternal} url={button.url}>
            <Button color="secondary">{button.text}</Button>
          </Link>
        )}
        <EcoChequeIcon className="ecocheque" />
      </div>
    </div>
  );
};

export default CMSEcoChequeTemplate;
