// @ts-nocheck => CMS components will eventually be replaced by CF components so no need to type them

import templates from './Template';

interface CMSMasonryContentBlockComponentProps {
  template: string;
}

const CMSMasonryContentBlockComponent = ({ template, ...props }: CMSMasonryContentBlockComponentProps) => {
  const Template = templates?.[template];

  if (!template || !Template) {
    return null;
  }

  return <Template {...props} />;
};

export default CMSMasonryContentBlockComponent;
