import CMSUspComponent from '../CMSUspComponent/CMSUspComponent';
import CMSBannerCarouselComponent from './CMSBannerCarouselComponent/CMSBannerCarouselComponent';
import CMSCategoryTileData from './CMSCategoryTileData/CMSCategoryTileData';
import CMSContentBlockComponent from './CMSContentBlockComponent/CMSContentBlockComponent';
import CMSContentBlockSlider from './CMSContentBlockSlider/CMSContentBlockSlider';
import CMSContentTileSlider from './CMSContentTileSlider/CMSContentTileSlider';
import CMSFooterComponent from './CMSFooterComponent/CMSFooterComponent';
import CMSHTMLComponent from './CMSHTMLComponent/CMSHTMLComponent';
import CMSInfoContainerData from './CMSInfoContainerData/CMSInfoContainerData';
import CMSLeaderBoardComponent from './CMSLeaderBoardComponent/CMSLeaderBoardComponent';
import CMSLoadBeeComponent from './CMSLoadBeeComponent/CMSLoadBeeComponent';
import CMSMasonryContentBlock from './CMSMasonryContentBlock/CMSMasonryContentBlockComponent';
import CMSMasonryContentTileData from './CMSMasonryContentTileData/CMSMasonryContentTileData';
import CMSMasonryProductTile from './CMSMasonryProductTileComponent/CMSMasonryProductTileComponent';
import CMSMasonryTitleComponent from './CMSMasonryTitleComponent/CMSMasonryTitleComponent';
import CMSParagraphComponent from './CMSParagraphComponent/CMSParagraphComponent';
import CMSProductSliderComponent from './CMSProductSliderComponent/CMSProductSliderComponent';
import CMSProductTilesComponent from './CMSProductTilesComponent/CMSProductTilesComponent';

const templates = {
  cmsCarouselComponentContainerWsDTO: CMSBannerCarouselComponent,
  cmsCategoryTileWsDTO: CMSCategoryTileData,
  cmsContentBlockSliderWsDTO: CMSContentBlockSlider,
  cmsContentBlockWsDTO: CMSContentBlockComponent,
  cmsContentTileSliderWsDTO: CMSContentTileSlider,
  cmsFaqContainerWsDTO: CMSInfoContainerData,
  cmsFooterComponentWsDTO: CMSFooterComponent,
  cmsLeaderBoardSliderWsDTO: CMSLeaderBoardComponent,
  cmsLeaderBoardWsDTO: CMSLeaderBoardComponent,
  cmsLoadBeeComponentWsDTO: CMSLoadBeeComponent,
  cmsMasonryContentBlockWsDTO: CMSMasonryContentBlock,
  cmsMasonryContentTileWsDTO: CMSMasonryContentTileData,
  cmsMasonryProductTileWsDTO: CMSMasonryProductTile,
  cmsMasonryTitleComponentWsDTO: CMSMasonryTitleComponent,
  cmsParagraphComponentWsDTO: CMSParagraphComponent,
  cmsProductsGroupWsDTO: CMSProductTilesComponent,
  cmshtmlComponentWsDTO: CMSHTMLComponent,
  cmsuspComponentWsDTO: CMSUspComponent,
  queryProductSliderComponentWsDTO: CMSProductSliderComponent,
};

export default templates;
