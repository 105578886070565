import { Icon as IconType } from 'constants/icon';
import { FontSize } from 'constants/styling';
import { useWebConfig } from 'features/configuration/queries';
import { Color } from 'types/Color';
import { type Signing } from 'types/Signing';
import Image from '../../Image/Image';
import Link from '../../Link/Link';
import Typography from '../../Typography/Typography';
import { SigningImageWrapper, SigningImageWrapperLink, StyledIcon, StyledTextSigning } from './Signing.styled';

interface SigningProps {
  className?: string;
  fontSize?: FontSize;
  icon?: IconType;
  isTextSigning?: boolean;
  linkable?: boolean;
  onClick?: () => void;
  showSigningModal?: (signing: Signing) => void;
  signing: Signing;
  textColor?: Color;
  withModal?: boolean;
}

/**
 * @deprecated
 * Use the new Signing component located in the ui folder instead
 */
const SigningComponent = ({
  className,
  fontSize = 88,
  icon,
  isTextSigning,
  linkable,
  onClick,
  showSigningModal,
  signing = { colour: 'secondary-200' },
  textColor = 'white',
  withModal,
}: SigningProps) => {
  const { data: webConfig } = useWebConfig();

  const isClickableLink = linkable && signing?.link;

  const imageComponent = signing?.image?.url && (
    <Image
      alt={signing?.image?.type ? signing?.image.type : 'image'}
      layout="fill"
      objectFit="contain"
      quality={webConfig?.imageQuality || 100}
      sizes={{
        default: 50,
      }}
      src={signing.image.url}
    />
  );

  if (isTextSigning && signing?.text) {
    return (
      <StyledTextSigning backgroundColor={signing?.colour} className={className} onClick={onClick}>
        {isClickableLink ? (
          <Link className={className} newTab url={{ pathname: signing?.link }}>
            <Typography color={textColor} fontSize={fontSize} fontWeight="bold">
              {signing.text}
            </Typography>
          </Link>
        ) : (
          <Typography color={textColor} fontSize={fontSize} fontWeight="bold">
            {signing.text}
          </Typography>
        )}
        {icon && <StyledIcon type="custom" name={icon} size={100} />}
      </StyledTextSigning>
    );
  }

  if (withModal && !!showSigningModal && imageComponent) {
    return (
      <SigningImageWrapper className={className}>
        <div
          className={className}
          onClick={() => {
            showSigningModal(signing);
          }}
          role="button"
          tabIndex={0}
        >
          {imageComponent}
        </div>
      </SigningImageWrapper>
    );
  }

  if (imageComponent) {
    if (signing?.link) {
      return (
        <SigningImageWrapperLink className={className} url={signing?.link} newTab>
          {imageComponent}
        </SigningImageWrapperLink>
      );
    } else {
      return <SigningImageWrapper className={className}>{imageComponent}</SigningImageWrapper>;
    }
  }

  return null;
};

export default SigningComponent;
