import Icon from 'components/Icon/Icon';
import { ICON_NAMES } from 'constants/icon';
import { FaqItem } from 'types/Faq';
import { scrollTo } from 'utils/scrollUtil';
import { getFaqQuestionSlug } from 'utils/slugUtil';
import { FaqItemLink, FaqQuestion } from './InfoItemOverview.styled';

interface FaqInfoOverviewProps {
  item: FaqItem;
}

const FaqInfoOverview = ({ item }: FaqInfoOverviewProps) => {
  const url = getFaqQuestionSlug(item);

  if (!url) {
    return null;
  }

  return (
    <FaqItemLink onClick={(event) => scrollTo(event, url)} role="button" tabIndex={0}>
      <FaqQuestion>{item.question}</FaqQuestion>
      <Icon type="fa" name={ICON_NAMES.ANGLE_RIGHT} size={100} color="whisper-300" />
    </FaqItemLink>
  );
};

export default FaqInfoOverview;
