// @ts-nocheck => CMS components will eventually be replaced by CF components so no need to type them

import templates from './Template';

interface CMSMasonryProductTileComponentProps {
  template?: string;
}

const CMSMasonryProductTileComponent = ({ template, ...props }: CMSMasonryProductTileComponentProps) => {
  if (template) {
    const Template = templates[`${template}_MASONRY`];

    if (Template) {
      return <Template {...props} />;
    }
  }
  return null;
};

export default CMSMasonryProductTileComponent;
