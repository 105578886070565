import styled from 'styled-components';
import { spacer, color } from 'theme';
import Typography from '../../../../../../../../Typography/Typography';

export const FaqItemLink = styled.li`
  display: flex;
  gap: ${spacer(50)};
  align-items: center;
  text-decoration: none;
`;

export const FaqQuestion = styled(Typography)`
  &:hover {
    color: ${color('primary-300')};
  }
`;
