import { FaqGroup } from 'types/Faq';
import ArrowRightIcon from '../../../../../../Icon/ArrowRightIcon/ArrowRightIcon';
import CMSPageLink from '../../../../../../Router/Link/CMSPageLink/CMSPageLink';

type CMSInfoContainerDataMinimalTemplateProps = { faqGroups: FaqGroup[] };

const CMSInfoContainerDataMinimalTemplate = ({ faqGroups }: CMSInfoContainerDataMinimalTemplateProps) => (
  <div className="anchor-overview">
    {faqGroups &&
      faqGroups.map((group, groupIndex) => (
        <ul key={groupIndex} className="anchor-list">
          <li className="head">{group.title}</li>
          {group.faqItems &&
            group.faqItems.map((faqItem, itemIndex) => {
              if (faqItem && faqItem.question) {
                return (
                  <li key={itemIndex} className="anchor-link">
                    <CMSPageLink page="faq">
                      <span>{faqItem.question}</span>
                      <ArrowRightIcon className="arrow-right" />
                    </CMSPageLink>
                  </li>
                );
              }
              return null;
            })}
        </ul>
      ))}
  </div>
);

export default CMSInfoContainerDataMinimalTemplate;
