import CMSSlotComponentBannerTemplate from './CMSSlotComponentBannerTemplate/CMSSlotComponentBannerTemplate';
import CMSSlotMasonryTemplate from './CMSSlotMasonryTemplate/CMSSlotMasonryTemplate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const templates: Record<string, any> = {
  COMPONENTBANNER: CMSSlotComponentBannerTemplate,
  MASONRY: CMSSlotMasonryTemplate,
};

export default templates;
