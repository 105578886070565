import { HTMLAttributes } from 'react';

const EcoChequeIcon = (props: HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="461px"
    height="151px"
    viewBox="138 127 461 151"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(138.000000, 127.000000)"
    >
      <g id="noun_688037_cc" transform="translate(374.000000, 0.000000)" fill="#49AF81">
        <g id="Group">
          <path
            d="M79.2255319,0.580769231 L7.77446809,0.580769231 C3.88723404,0.580769231 0.925531915,3.87179487 0.925531915,7.74358974 L0.925531915,135.125641 C0.925531915,138.223077 2.77659574,140.739744 5.18297872,141.707692 L5.18297872,148.289744 C5.18297872,149.451282 5.92340426,150.225641 7.03404255,150.225641 L79.9659574,150.225641 C81.0765957,150.225641 81.8170213,149.451282 81.8170213,148.289744 L81.8170213,141.707692 C84.4085106,140.546154 86.0744681,138.029487 86.0744681,135.125641 L86.0744681,7.74358974 C86.0744681,3.87179487 82.9276596,0.580769231 79.2255319,0.580769231 Z M7.77446809,4.4525641 L79.2255319,4.4525641 C81.0765957,4.4525641 82.3723404,6.00128205 82.3723404,7.74358974 L82.3723404,59.2384615 L4.81276596,59.2384615 L4.62765957,59.2384615 L4.62765957,7.74358974 C4.44255319,6.00128205 5.92340426,4.4525641 7.77446809,4.4525641 Z M78.1148936,146.547436 L8.88510638,146.547436 L8.88510638,142.482051 L78.1148936,142.482051 L78.1148936,146.547436 Z M79.2255319,138.610256 L7.77446809,138.610256 C5.92340426,138.610256 4.62765957,137.061538 4.62765957,135.319231 L4.62765957,63.1102564 L4.81276596,63.1102564 L82.3723404,63.1102564 L82.3723404,135.319231 C82.3723404,137.061538 80.8914894,138.610256 79.2255319,138.610256 Z"
            id="Shape"
          />
          <path
            d="M16.2893617,48.0102564 C17.4,48.0102564 18.1404255,47.2358974 18.1404255,46.074359 L18.1404255,38.9115385 C18.1404255,37.75 17.4,36.975641 16.2893617,36.975641 C15.1787234,36.975641 14.4382979,37.75 14.4382979,38.9115385 L14.4382979,46.074359 C14.4382979,47.2358974 15.3638298,48.0102564 16.2893617,48.0102564 Z"
            id="Shape"
          />
          <path
            d="M16.2893617,75.3064103 C15.1787234,75.3064103 14.4382979,76.0807692 14.4382979,77.2423077 L14.4382979,84.4051282 C14.4382979,85.5666667 15.1787234,86.3410256 16.2893617,86.3410256 C17.4,86.3410256 18.1404255,85.5666667 18.1404255,84.4051282 L18.1404255,77.2423077 C18.1404255,76.274359 17.4,75.3064103 16.2893617,75.3064103 Z"
            id="Shape"
          />
        </g>
      </g>
      <g id="noun_654215_cc" transform="translate(256.000000, 43.000000)" fill="#49AF81">
        <g id="Artboard-12">
          <path
            d="M6.40625,107.892 L75.209375,107.892 C78.3936418,107.892 80.975,105.17213 80.975,101.817 L80.975,6.6015 C80.975,3.24637014 78.3936418,0.5265 75.209375,0.5265 L6.40625,0.5265 C3.22198324,0.5265 0.640625,3.24637014 0.640625,6.6015 L0.640625,101.817 C0.640625,105.17213 3.22198324,107.892 6.40625,107.892 L6.40625,107.892 Z M4.484375,6.642 C4.484375,5.52362338 5.34482775,4.617 6.40625,4.617 L75.209375,4.617 C76.2707973,4.617 77.13125,5.52362338 77.13125,6.642 L77.13125,25.542 L4.433125,25.542 L4.484375,6.642 Z M4.484375,29.592 L77.13125,29.592 L77.13125,101.817 C77.13125,102.935377 76.2707973,103.842 75.209375,103.842 L6.40625,103.842 C5.34482775,103.842 4.484375,102.935377 4.484375,101.817 L4.484375,29.592 Z"
            id="Shape"
          />
          <path
            d="M41,96.7275 C52.639772,96.7548149 63.1478382,89.387973 67.6201604,78.0650259 C72.0924827,66.7420788 69.6473896,53.6952269 61.4259883,45.0133412 C53.2045869,36.3314554 40.8276405,33.7260787 30.07138,38.4131212 C19.3151195,43.1001638 12.3000289,54.1556245 12.3,66.42 C12.3140354,83.1259082 25.1449264,96.6754632 41,96.7275 L41,96.7275 Z M41,40.176 C51.0862806,40.1486813 60.1935863,46.5302275 64.0702207,56.3414483 C67.946855,66.1526691 65.8282715,77.458604 58.7035195,84.9811383 C51.5787674,92.5036727 40.8529841,94.7592187 31.5334438,90.6947869 C22.2139034,86.6303552 16.1385976,77.0475294 16.14375,66.42 C16.1577674,51.9508335 27.2677708,40.2145131 41,40.1625 L41,40.176 Z"
            id="Shape"
          />
          <path
            d="M41,84.186 C47.8255089,84.2133245 53.9933665,79.9021449 56.6233645,73.2656555 C59.2533626,66.6291661 57.8266413,58.9766479 53.0094377,53.8816021 C48.1922341,48.7865564 40.9349111,47.2541601 34.6264253,49.9999973 C28.3179395,52.7458346 24.2028618,59.2281924 24.2028125,66.42 C24.2167658,76.1994818 31.7186678,84.1340714 41,84.186 Z M41,52.65 C46.2661662,52.6227361 51.0291461,55.9413452 53.0671558,61.0577979 C55.1051654,66.1742505 54.016671,72.0804906 50.3094219,76.0214384 C46.6021729,79.9623861 41.0065803,81.1615862 36.1328167,79.0596486 C31.2590531,76.957711 28.0673593,71.9687646 28.0465625,66.42 C28.0252532,58.8531347 33.8185775,52.6946092 41,52.65 L41,52.65 Z"
            id="Shape"
          />
          <ellipse id="Oval" cx="14.35" cy="15.1605" rx="2.10125" ry="2.214" />
          <ellipse id="Oval" cx="23.11375" cy="15.1605" rx="2.10125" ry="2.214" />
          <ellipse id="Oval" cx="31.8646875" cy="15.1605" rx="2.10125" ry="2.214" />
        </g>
      </g>
      <g id="noun_417965_cc" transform="translate(0.000000, 82.000000)" fill="#49AF81">
        <g id="Group">
          <g id="Shape">
            <path d="M91.8661765,0.0204545455 L7.13382353,0.0204545455 C3.34852941,0.0204545455 0.291176471,3.21818182 0.291176471,7.17727273 L0.291176471,56.6659091 C0.291176471,60.625 3.34852941,63.8227273 7.13382353,63.8227273 L9.02647059,63.8227273 L9.02647059,64.8886364 C9.02647059,66.5636364 10.3367647,68.0863636 12.0838235,68.0863636 L15.8691176,68.0863636 C17.4705882,68.0863636 18.9264706,66.7159091 18.9264706,64.8886364 L18.9264706,63.8227273 L80.2191176,63.8227273 L80.2191176,64.8886364 C80.2191176,66.5636364 81.5294118,68.0863636 83.2764706,68.0863636 L87.0617647,68.0863636 C88.6632353,68.0863636 90.1191176,66.7159091 90.1191176,64.8886364 L90.1191176,63.8227273 L92.0117647,63.8227273 C95.7970588,63.8227273 98.8544118,60.625 98.8544118,56.6659091 L98.8544118,7.17727273 C98.7088235,3.21818182 95.6514706,0.0204545455 91.8661765,0.0204545455 Z M15.8691176,64.7363636 C15.8691176,64.8886364 15.8691176,64.8886364 15.8691176,64.7363636 L11.9382353,64.8886364 C11.9382353,64.8886364 11.7926471,64.8886364 11.7926471,64.7363636 L11.7926471,63.6704545 L15.8691176,63.6704545 L15.8691176,64.7363636 Z M87.0617647,64.7363636 C87.0617647,64.8886364 87.0617647,64.8886364 87.0617647,64.7363636 L83.1308824,64.8886364 C83.1308824,64.8886364 82.9852941,64.8886364 82.9852941,64.7363636 L82.9852941,63.6704545 L87.0617647,63.6704545 L87.0617647,64.7363636 Z M95.7970588,56.6659091 C95.7970588,58.95 94.05,60.7772727 91.8661765,60.7772727 L7.13382353,60.7772727 C4.95,60.7772727 3.20294118,58.95 3.20294118,56.6659091 L3.20294118,7.17727273 C3.20294118,4.89318182 4.95,3.06590909 7.13382353,3.06590909 L91.8661765,3.06590909 C94.05,3.06590909 95.7970588,4.89318182 95.7970588,7.17727273 L95.7970588,56.6659091 Z" />
            <path d="M72.6485294,7.78636364 L10.0455882,7.78636364 C8.88088235,7.78636364 7.86176471,8.85227273 7.86176471,10.0704545 L7.86176471,53.7727273 C7.86176471,54.9909091 8.88088235,56.0568182 10.0455882,56.0568182 L72.6485294,56.0568182 C73.8132353,56.0568182 74.8323529,54.9909091 74.8323529,53.7727273 L74.8323529,9.91818182 C74.6867647,8.7 73.8132353,7.78636364 72.6485294,7.78636364 Z M10.7735294,10.8318182 L61.1470588,10.8318182 C56.925,16.9227273 54.7411765,24.2318182 54.7411765,31.6931818 C54.7411765,39.4590909 57.0705882,46.7681818 61.4382353,53.0113636 L10.7735294,53.0113636 L10.7735294,10.8318182 Z M71.775,53.0113636 L65.2235294,53.0113636 C60.4191176,47.0727273 57.7985294,39.6113636 57.7985294,31.6931818 C57.7985294,24.0795455 60.4191176,16.6181818 64.9323529,10.8318182 L71.775,10.8318182 L71.775,53.0113636 Z" />
            <path d="M85.0235294,41.8954545 C81.2382353,41.8954545 78.3264706,45.0931818 78.3264706,48.9 C78.3264706,52.7068182 81.3838235,55.9045455 85.0235294,55.9045455 C88.6632353,55.9045455 91.7205882,52.7068182 91.7205882,48.9 C91.7205882,45.0931818 88.8088235,41.8954545 85.0235294,41.8954545 Z M85.0235294,53.0113636 C82.8397059,53.0113636 81.2382353,51.1840909 81.2382353,49.0522727 C81.2382353,46.9204545 82.9852941,45.0931818 85.0235294,45.0931818 C87.0617647,45.0931818 88.8088235,46.9204545 88.8088235,49.0522727 C88.8088235,51.1840909 87.2073529,53.0113636 85.0235294,53.0113636 Z" />
            <path d="M85.0235294,18.5977273 C87.9352941,18.5977273 90.2647059,16.1613636 90.2647059,13.1159091 C90.2647059,10.0704545 87.9352941,7.63409091 85.0235294,7.63409091 C82.1117647,7.63409091 79.7823529,10.0704545 79.7823529,13.1159091 C79.9279412,16.1613636 82.2573529,18.5977273 85.0235294,18.5977273 Z M85.0235294,10.8318182 C86.3338235,10.8318182 87.3529412,11.8977273 87.3529412,13.2681818 C87.3529412,14.6386364 86.3338235,15.7045455 85.0235294,15.7045455 C83.7132353,15.7045455 82.6941176,14.6386364 82.6941176,13.2681818 C82.6941176,11.8977273 83.8588235,10.8318182 85.0235294,10.8318182 Z" />
            <path d="M90.8470588,20.7295455 L79.4911765,20.7295455 C78.4720588,20.7295455 77.7441176,21.6431818 77.7441176,22.5568182 L77.7441176,37.7840909 C77.7441176,38.85 78.6176471,39.6113636 79.4911765,39.6113636 L90.8470588,39.6113636 C91.8661765,39.6113636 92.5941176,38.6977273 92.5941176,37.7840909 L92.5941176,22.5568182 C92.5941176,21.6431818 91.7205882,20.7295455 90.8470588,20.7295455 Z M89.6823529,23.775 L89.6823529,26.0590909 L80.5102941,26.0590909 L80.5102941,23.775 L89.6823529,23.775 Z M89.6823529,29.1045455 L89.6823529,31.3886364 L80.5102941,31.3886364 L80.5102941,29.1045455 L89.6823529,29.1045455 Z M80.5102941,36.7181818 L80.5102941,34.4340909 L89.6823529,34.4340909 L89.6823529,36.7181818 L80.5102941,36.7181818 Z" />
            <path d="M64.35,28.0386364 C64.4955882,27.2772727 63.9132353,26.3636364 63.1852941,26.3636364 C62.3117647,26.2113636 61.5838235,26.8204545 61.5838235,27.5818182 C61.4382353,28.9522727 61.2926471,30.3227273 61.2926471,31.6931818 C61.2926471,33.0636364 61.4382353,34.4340909 61.5838235,35.8045455 C61.7294118,36.5659091 62.3117647,37.175 63.0397059,37.175 L63.1852941,37.175 C63.9132353,37.0227273 64.4955882,36.2613636 64.35,35.5 C64.2044118,34.2818182 64.0588235,33.0636364 64.0588235,31.8454545 C64.2044118,30.475 64.2044118,29.2568182 64.35,28.0386364 Z" />
            <path d="M45.7147059,25.7545455 L44.9867647,26.3636364 C43.2397059,27.7340909 41.9294118,28.6477273 38.8720588,28.6477273 C36.3970588,28.6477273 35.2323529,27.8863636 33.9220588,26.9727273 C32.3205882,25.9068182 30.7191176,24.8409091 27.3705882,24.8409091 C24.0220588,24.8409091 22.275,26.0590909 20.8191176,26.9727273 L20.2367647,27.4295455 C19.5088235,27.8863636 19.3632353,28.8 19.8,29.5613636 C20.2367647,30.3227273 21.1102941,30.475 21.8382353,30.0181818 C22.1294118,29.8659091 22.275,29.7136364 22.5661765,29.5613636 C23.8764706,28.6477273 25.0411765,27.8863636 27.5161765,27.8863636 C29.9911765,27.8863636 31.1558824,28.6477273 32.4661765,29.5613636 C34.0676471,30.6272727 35.6691176,31.6931818 39.0176471,31.6931818 C43.0941176,31.6931818 45.1323529,30.1704545 46.8794118,28.8 L47.6073529,28.3431818 C48.1897059,27.8863636 48.4808824,26.9727273 47.8985294,26.2113636 C47.3161765,25.45 46.4426471,25.2977273 45.7147059,25.7545455 Z" />
            <path d="M45.7147059,33.6727273 L44.9867647,34.2818182 C43.2397059,35.6522727 41.9294118,36.5659091 38.8720588,36.5659091 C36.3970588,36.5659091 35.2323529,35.8045455 33.9220588,34.8909091 C32.3205882,33.825 30.7191176,32.7590909 27.3705882,32.7590909 C24.0220588,32.7590909 22.275,33.9772727 20.8191176,34.8909091 L20.2367647,35.3477273 C19.5088235,35.8045455 19.3632353,36.7181818 19.8,37.4795455 C20.2367647,38.2409091 21.1102941,38.3931818 21.8382353,37.9363636 C22.1294118,37.7840909 22.275,37.6318182 22.5661765,37.4795455 C23.8764706,36.5659091 25.0411765,35.8045455 27.5161765,35.8045455 C29.9911765,35.8045455 31.1558824,36.5659091 32.4661765,37.4795455 C34.0676471,38.5454545 35.6691176,39.6113636 39.0176471,39.6113636 C43.0941176,39.6113636 45.1323529,38.0886364 46.8794118,36.7181818 L47.6073529,36.2613636 C48.1897059,35.8045455 48.4808824,34.8909091 47.8985294,34.1295455 C47.3161765,33.3681818 46.4426471,33.2159091 45.7147059,33.6727273 Z" />
          </g>
        </g>
      </g>
      <path
        d="M219.162243,64 L135.378919,64 C132.897532,64 131,65.8975317 131,68.3789194 L131,128.808007 C131,131.289394 132.897532,133.186926 135.378919,133.186926 L175.373049,133.186926 L175.373049,147.491396 L166.907139,147.491396 C165.885391,147.491396 165.155571,148.36718 165.155571,149.242964 C165.155571,150.118747 166.031355,150.994531 166.907139,150.994531 L187.634024,150.994531 C188.655772,150.994531 189.385591,150.118747 189.385591,149.242964 C189.385591,148.36718 188.509808,147.491396 187.634024,147.491396 L179.168113,147.491396 L179.168113,133.186926 L219.162243,133.186926 C221.643631,133.186926 223.541162,131.289394 223.541162,128.808007 L223.541162,68.3789194 C223.541162,65.8975317 221.497667,64 219.162243,64 Z M136.838559,67.6490995 L217.702603,67.6490995 C218.870315,67.6490995 219.892063,68.6708473 219.892063,69.8385591 L219.892063,117.276852 L134.649099,117.276852 L134.649099,69.8385591 C134.649099,68.5248833 135.670847,67.6490995 136.838559,67.6490995 Z M217.702603,129.391862 L136.838559,129.391862 C135.670847,129.391862 134.649099,128.370115 134.649099,127.202403 L134.649099,120.779988 L219.892063,120.779988 L219.892063,127.202403 C219.892063,128.370115 218.870315,129.391862 217.702603,129.391862 Z"
        id="Shape"
        fill="#49AF81"
      />
    </g>
  </svg>
);

export default EcoChequeIcon;
