import { ProductCard } from 'components/Product/ProductCard';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { EmblaOptionsType } from 'embla-carousel';
import useProductCard from 'hooks/useProductCard';
import breakpointValues from 'theme/variables/breakpoints';
import { CMSContentBlock } from 'types/CMS';
import { Product } from 'types/Product';
import Link from '../../../../../../Link/Link';
import Typography from '../../../../../../Typography/Typography';
import { StyledMasonry } from '../../../CMSMasonryComponents.styled';

interface CMSProductTilesFolderTemplateProps {
  centered?: boolean;
  contentBlock?: CMSContentBlock;
  products?: Product[];
}

const CMSProductTilesFolderTemplate = ({
  centered = false,
  contentBlock,
  products,
}: CMSProductTilesFolderTemplateProps) => {
  const folderProducts =
    products &&
    products.filter(
      (product) => product.inFolder && product.folderEndDate && new Date(product.folderEndDate) > new Date(),
    );

  const options: EmblaOptionsType = {
    breakpoints: {
      [`(min-width: ${breakpointValues.md}px)`]: { slidesToScroll: 2 },
      [`(min-width: ${breakpointValues.lg}px)`]: { slidesToScroll: 3 },
      [`(min-width: ${breakpointValues.xl}px)`]: { slidesToScroll: 4 },
    },
  };

  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.7,

    [BREAKPOINTS.MD]: 2.5,
    [BREAKPOINTS.LG]: 3,
  };

  const showArrows = { [BREAKPOINTS.LG]: true };

  const { mapProductCardProps } = useProductCard();

  if (folderProducts && folderProducts.length > 0) {
    return (
      <StyledMasonry>
        <section className={`folder-section webexclusive grid md:grid-cols-3 ${centered ? 'centered' : ''}`}>
          {contentBlock && (
            <div className="grid-item">
              <div className="grid-item-content intro_with_button">
                <div className="content-wrap">
                  {contentBlock?.title && (
                    <Typography className="folder-title" fontSize={150} type="h1">
                      {contentBlock.title}
                    </Typography>
                  )}

                  {contentBlock.text &&
                    contentBlock.text.length > 0 &&
                    contentBlock.text.map((line, index) => (
                      <div key={index} className="f-bold mt-md" dangerouslySetInnerHTML={{ __html: line }} />
                    ))}

                  {contentBlock?.button?.url && (
                    <Link className="btn btn-fill dark-blue mt-10" url={contentBlock.button.url}>
                      {contentBlock.button.text}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="folder-slider md:col-span-2">
            <SliderEmbla slidesToShow={slidesToShow} options={options} showArrows={showArrows}>
              {folderProducts.map((product) => {
                const productCardProps = mapProductCardProps({ product });
                return <ProductCard key={product.code} className="grid-item one-by-one" {...productCardProps} />;
              })}
            </SliderEmbla>
          </div>
        </section>
      </StyledMasonry>
    );
  }

  return null;
};

export default CMSProductTilesFolderTemplate;
