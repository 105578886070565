import { FaqGroup } from 'types/Faq';
import FaqGroupDetail from '../Info/Detail/InfoGroupDetail/InfoGroupDetail';

type Props = {
  faqGroups: FaqGroup[];
};
const CMSInfoContainerDataGiftCardTemplate = ({ faqGroups }: Props) =>
  faqGroups?.length ? (
    <div>
      {faqGroups.map((group, index) => (
        <FaqGroupDetail key={index} group={group} withTitle={false} />
      ))}
    </div>
  ) : null;

export default CMSInfoContainerDataGiftCardTemplate;
