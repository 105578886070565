import { FaqGroup } from 'types/Faq';
import templates from './Template';

type CMSFaqContainerDataProps = {
  faqGroups: FaqGroup[];
  template: keyof typeof templates;
};

const CMSFaqContainerData = ({ faqGroups, template = 'Full' }: CMSFaqContainerDataProps) => {
  if (template) {
    const Template = templates?.[template];

    if (Template) {
      return <Template faqGroups={faqGroups} />;
    }
  }
  return null;
};

export default CMSFaqContainerData;
