// @ts-nocheck => CMS components will eventually be replaced by CF components so no need to type them
import Button from 'components/Button/Button';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ICON_NAMES } from 'constants/icon';
import { EmblaOptionsType } from 'embla-carousel';
import useWindowSize from 'hooks/useWindowSize';
import { useIntl } from 'react-intl';
import { CMSLeaderBoard, CMSLeaderBoardsComponent } from 'types/CMS';
import { removeWhiteSpaces } from 'utils/textUtil';
import { CmsLeaderBoardComponentWrapper } from './CMSLeaderBoardComponent.styled';

interface CMSLeaderBoardComponentProps {
  className?: string;
  cmsComponent?: CMSLeaderBoardsComponent;
  isMasonry?: boolean;
  leaderBoards?: CMSLeaderBoard[];
}

const CMSLeaderBoardComponent = (props: CMSLeaderBoardComponentProps) => {
  const { className, cmsComponent, isMasonry = true, leaderBoards } = props;

  const { formatMessage } = useIntl();
  const { isTablet } = useWindowSize();

  const getLeaderBoardContent = (component) => {
    const imageUrl = isTablet
      ? component?.mobileBackgroundImage?.url || component?.mobileBackgroundImageMultiBanner?.url
      : component?.backgroundImage?.url || component?.backgroundImageMultiBanner?.url;

    if (!component || !imageUrl) {
      return null;
    }

    const { linkUrl, subtitle, title, uid } = component;

    const body = (
      <div className="leader-board-content">
        {(title || subtitle) && (
          <div className="leader-board-copy">
            {title && (
              <Typography className="leader-board-title" color="white" fontSize={125} fontWeight="bold">
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography className="leader-board-subtitle" color="white" fontWeight="bold">
                {subtitle}
              </Typography>
            )}
          </div>
        )}

        <Button
          aria-label={formatMessage({ id: 'leader_board_button_label' })}
          className="leader-board-button"
          color="secondary"
          icon={ICON_NAMES.ANGLE_RIGHT}
        />
      </div>
    );

    return (
      <div key={uid || title} className="leader-board-wrapper">
        {imageUrl && (
          <Image
            alt="img"
            layout="fill"
            objectFit="contain"
            sizes={{
              default: 400,
              md: 1000,
            }}
            src={imageUrl}
          />
        )}
        {linkUrl ? (
          <Link newTab url={linkUrl}>
            {body}
          </Link>
        ) : (
          body
        )}
      </div>
    );
  };

  const sliderSettings: EmblaOptionsType = {
    slidesToScroll: isTablet ? 1 : 2,
  };

  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.5,
    [BREAKPOINTS.MD]: 2,
  };

  const currentLeaderBoards =
    (!!leaderBoards?.length && leaderBoards) ||
    (!!cmsComponent?.leaderboards?.length && cmsComponent.leaderboards) ||
    (cmsComponent && [cmsComponent]) ||
    (isMasonry && [props]);

  if (!currentLeaderBoards?.length) {
    return null;
  }

  return (
    <CmsLeaderBoardComponentWrapper className={`${isMasonry ? 'masonry-leader-board container' : ''} ${className}`}>
      {currentLeaderBoards.length > 1 ? (
        <SliderEmbla slidesToShow={slidesToShow} showArrows={{ [BREAKPOINTS.MD]: true }} options={sliderSettings}>
          {currentLeaderBoards.map((leaderBoardComponent, index) => (
            <div
              key={leaderBoardComponent?.title || leaderBoardComponent?.linkUrl}
              className={removeWhiteSpaces(
                `slick-div ${index === 0 ? 'first' : ''} ${index === currentLeaderBoards.length - 1 ? 'last' : ''}`,
              )}
            >
              {getLeaderBoardContent(leaderBoardComponent)}
            </div>
          ))}
        </SliderEmbla>
      ) : (
        <div className="single-slide">{getLeaderBoardContent(currentLeaderBoards[0])}</div>
      )}
    </CmsLeaderBoardComponentWrapper>
  );
};

export default CMSLeaderBoardComponent;
