import styled from 'styled-components';
import { color, spacer, variable } from '../../../theme';

export const StyledCategoryLinks = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${variable('box-shadow')};
  border-radius: ${variable('border-radius')};
  height: 100%;
  padding: ${spacer(75)} 0px ${spacer(75)} ${spacer(100)};
`;

export const StyledSlogan = styled.div`
  color: ${color('primary-300')};
  padding: 0px 0px ${spacer(75)} ${spacer(100)};
`;

export const StyledCategoryOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: calc(24% - 4px);
  height: 100%;
  z-index: 50;
  overflow-y: scroll;
  background-color: ${color('whisper-100')};
`;
