import { StyledMasonry } from 'components/CMS/CMSComponent/components/CMSMasonryComponents.styled';
import { ReactNode } from 'react';
import Masonry from 'react-masonry-component';

type CMSSlotMasonryTemplateProps = {
  children: ReactNode;
  className?: string;
};

const CMSSlotMasonryTemplate = ({ children, className, ...props }: CMSSlotMasonryTemplateProps) => {
  const masonryOptions = {
    columnWidth: '.c-masonry-sizer',
    percentPosition: true,
  };

  return (
    <StyledMasonry className="container">
      {/* @ts-ignore */}
      <Masonry {...props} className={`masonry c-masonry ${className}`} elementType="section" options={masonryOptions}>
        <div className="c-masonry-sizer" />
        {children}
      </Masonry>
    </StyledMasonry>
  );
};

export default CMSSlotMasonryTemplate;
