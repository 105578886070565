import { EnergyLabel } from 'components/Layout';
import styled from 'styled-components';
import { spacer } from 'theme';

export const StyledEnergyLabel = styled(EnergyLabel)`
  position: absolute;
  top: 50%;
  left: ${spacer(100)};
  transform: translateY(-50%);
`;
