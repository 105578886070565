import styled from 'styled-components';
import { color } from 'theme';
import Typography from '../../../../../../../../Typography/Typography';

export const FaqItemDetailHtml = styled(Typography)`
  a {
    color: ${color('primary-300')};
    text-decoration: none;

    &:hover {
      color: ${color('primary-400')};
    }
  }
`;
