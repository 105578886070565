import CMSProductTilesDealsTemplate from './CMSProductTilesDealsTemplate/CMSProductTilesDealsTemplate';
import CMSProductTilesFolderTemplate from './CMSProductTilesFolderTemplate/CMSProductTilesFolderTemplate';
import CMSProductTilesRegularTemplate from './CMSProductTilesRegularTemplate/CMSProductTilesRegularTemplate';
import CMSProductTilesWebExclusiveTemplate from './CMSProductTilesWebExclusiveTemplate/CMSProductTilesWebExclusiveTemplate';

const templates = {
  DEAL: CMSProductTilesDealsTemplate,
  FOLDER: CMSProductTilesFolderTemplate,
  REGULAR: CMSProductTilesRegularTemplate,
  WEBEXCLUSIVE: CMSProductTilesWebExclusiveTemplate,
} as const;
export default templates;
