import { ProductCard } from 'components/Product/ProductCard';
import useProductCard from 'hooks/useProductCard';
import { Product } from 'types/Product';
import { cn } from 'utils/cn';
import { StyledMasonry } from '../../../CMSMasonryComponents.styled';

type CMSProductTilesRegularTemplate = { centered?: boolean; products?: Product[] };

/**
 * @param {object} products - The products list.
 * @param {boolean} centered - The centered flag.
 * @returns {jsx} The product list component.
 */
const CMSProductTilesRegularTemplate = ({ centered, products }: CMSProductTilesRegularTemplate) => {
  const { mapProductCardProps } = useProductCard();

  if (!products?.length) return null;

  return (
    <StyledMasonry className="container">
      <div className={cn('mgrid-1 masonry grid', centered ? 'centered' : 'row')}>
        {products.map((product) => {
          const productCardProps = mapProductCardProps({ product });
          return <ProductCard key={product.code} className="grid-item one-by-one" {...productCardProps} />;
        })}
      </div>
    </StyledMasonry>
  );
};

export default CMSProductTilesRegularTemplate;
