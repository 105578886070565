import styled, { css } from 'styled-components';
import { breakpointUp } from 'theme';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';

export const CmsContentTileSliderComponent = styled(SliderEmbla)`
  width: 100%;
  max-width: 100vw;
  min-height: 150px;
  overflow: hidden;
  display: block;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: none;
    `
  )};

  max-width: 300px;

  .c-masonry-sizer,
  .c-masonry--1x1 {
    width: 100% !important; //TODO: Update Masonry to remove !important
  }
`;
