import templates from './ProductTemplate';

type CMSProductTilesComponentProps = {
  template: keyof typeof templates;
};

const CMSProductTilesComponent = ({ template, ...props }: CMSProductTilesComponentProps) => {
  const CMSProductTemplate = templates[template];

  if (!CMSProductTemplate) {
    return null;
  }
  return <CMSProductTemplate {...props} />;
};

export default CMSProductTilesComponent;
