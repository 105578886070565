import { CMSButton } from 'types/CMS';
import Button from '../../../../../../Button/Button';
import Link from '../../../../../../Link/Link';

type CMSContentBlockUSPTemplateProps = { button?: CMSButton; index: number; text?: string[]; title?: string };

const CMSContentBlockUSPTemplate = ({ button, index, text = [], title }: CMSContentBlockUSPTemplateProps) => (
  <div className={`${index % 2 === 0 ? 'left-part' : 'right-part'} part lightblue-with-text`}>
    <div className="content-wrap">
      <h3 className="title radikal h-style-1 pb-s">{title}</h3>
      {text && (
        <ul className="checklist">
          {text.map((line, lineIndex) => (
            <li dangerouslySetInnerHTML={{ __html: line }} key={lineIndex} />
          ))}
        </ul>
      )}
      {button?.url && button?.text && (
        <Link newTab={button.isExternal} url={button.url}>
          <Button>{button.text}</Button>
        </Link>
      )}
    </div>
  </div>
);

export default CMSContentBlockUSPTemplate;
