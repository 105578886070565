// @ts-nocheck => CMS components will eventually be replaced by CF components so no need to type them

import { memo, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { CMSPageData } from 'types/CMS';
import CMSComponent from '../CMSComponent/CMSComponent';
import templates from './Template';

interface CMSSlotProps {
  className?: string;
  cmsPage?: CMSPageData;
  position?: string | number;
}

const CMSSlot = ({ className, cmsPage, position }: CMSSlotProps) => {
  const slot = useMemo(
    () => cmsPage?.slots?.find((pageSlot) => pageSlot?.position === position?.toString()),
    [cmsPage],
  );

  const TemplateComponent = slot?.template ? templates?.[slot.template] : undefined;

  const components = useMemo(
    () =>
      slot?.components?.map((component, index) => (
        <CMSComponent
          key={`CMSComponent_${component?.uid}`}
          cmsComponent={component}
          index={index}
          slotTemplate={slot?.template}
        />
      )),
    [slot],
  );

  if (TemplateComponent && components?.length) {
    return <TemplateComponent className={className}>{components.map((component) => component)}</TemplateComponent>;
  }

  return <div className={className}>{components?.map((component) => component)}</div>;
};

export default memo(CMSSlot, isEqual);
