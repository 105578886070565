import CategoryDropDownLeft from 'components/Header/MainBar/Category/CategoryDropDownLeft/CategoryDropDownLeft';
import { useNavigation } from 'features/navigation/queries';
import { Category } from 'types/Category';
import CategoryDropDownMiddle from '../../Header/MainBar/Category/CategoryDropDownMiddle/CategoryDropDownMiddle';
import { StyledCategoryLinks, StyledCategoryOverlay, StyledSlogan } from './CategoryNavigationOverlay.styled';

interface CategoryNavigationOverlayProps {
  activeCategory?: Category;
  setActiveCategory: (category: Category) => void;
  slogan: string;
}

const CategoryNavigationOverlay = ({ activeCategory, setActiveCategory, slogan }: CategoryNavigationOverlayProps) => {
  const { data: navigation } = useNavigation();

  if (!navigation) return null;

  return (
    <div className="h-full">
      <StyledCategoryLinks>
        <CategoryDropDownLeft
          navigation={navigation}
          activeL1Category={activeCategory}
          setCurrentL1Category={setActiveCategory}
        />
        {slogan && <StyledSlogan>{slogan}</StyledSlogan>}
      </StyledCategoryLinks>
      {!!activeCategory?.subcategoryList?.length && (
        <StyledCategoryOverlay>
          <CategoryDropDownMiddle category={activeCategory} show />
        </StyledCategoryOverlay>
      )}
    </div>
  );
};

export default CategoryNavigationOverlay;
