import Button from 'components//Button/Button';
import Image from 'components//Image/Image';
import Link from 'components/Link/Link';
import Typography from 'components/Typography/Typography';
import { useMemo } from 'react';
import { CMSButton } from 'types/CMS';
import { Image as ImageType } from 'types/Image';

interface CMSMasonryContentBlock1x1TextTopProps {
  button: CMSButton;
  image: ImageType;
  text: string | string[];
  title: string;
}

const CMSMasonryContentBlock1x1TextTop = ({ button, image, text, title }: CMSMasonryContentBlock1x1TextTopProps) => {
  const wrappedImage = useMemo(() => {
    const imageComponent = image?.url && (
      <Image alt={title} className="o-box__img" layout="fill" objectFit="cover" quality={100} src={image.url} />
    );

    if (button?.url) {
      return (
        <Link url={button.url} newTab={button?.isExternal}>
          {imageComponent}
        </Link>
      );
    } else {
      return imageComponent;
    }
  }, [image, button]);

  return (
    <div className="c-masonry__item c-masonry__item--1x1">
      <div className="o-box o-box--border">
        <div className="o-box__content">
          {title && (
            <Typography fontWeight="normal" type="h3">
              {title}
            </Typography>
          )}
          {text && <Typography type="p">{text}</Typography>}
          {button?.url && !button.isExternal && (
            <Link url={button.url}>
              <Button color="secondary">{button.text}</Button>
            </Link>
          )}
          {button?.url && button.isExternal && (
            <Link url={button.url}>
              <Button color="secondary">{button.text}</Button>
            </Link>
          )}
        </div>
        {wrappedImage}
      </div>
    </div>
  );
};

export default CMSMasonryContentBlock1x1TextTop;
