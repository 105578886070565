import ProductTile from 'components/Product/ProductTile/ProductTile';
import { Product } from 'types/Product';

interface CMSMasonryProductTileRegularTemplateProps {
  product?: Product;
  products?: Product[];
}

const CMSMasonryProductTileRegularTemplate = ({ product, products }: CMSMasonryProductTileRegularTemplateProps) => {
  const productToShow = products?.[0] || product;

  if (!productToShow) return null;

  return (
    <div className="c-masonry__item c-masonry--1x1 product inline-block">
      <ProductTile className="grid-item one-by-one" product={productToShow} />
    </div>
  );
};

export default CMSMasonryProductTileRegularTemplate;
