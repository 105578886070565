import { Platform } from 'types/Platform';
import { CURRENT_BRAND } from './brand';

const LOADBEE_LOCALE_CONFIG: Record<Platform, Record<string, string[]>> = {
  hifi: {
    de: ['de_LU', 'de_DE'],
    en: ['en_LU', 'en_GB', 'en_US'],
    fr: ['fr_LU', 'fr_FR', 'fr_BE'],
  },
  krefel: {
    fr: ['fr_BE', 'fr_FR'],
    nl: ['nl_BE', 'nl_NL'],
  },
  tones: {
    fr: ['fr_BE', 'fr_FR'],
    nl: ['nl_BE', 'nl_NL'],
  },
} as const;

export const CURRENT_LOADBEE_LOCALE_CONFIG = LOADBEE_LOCALE_CONFIG[CURRENT_BRAND];
