import styled, { css } from 'styled-components';
import { breakpointUp, color, fontSize, spacer, specific, variable } from 'theme';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';

const buttonColor = specific('button', 'secondary-color');
const hoverColor = specific('button', 'secondary-color-hover');
const fontColor = specific('button', 'secondary-font-color');

export const StyledCmsHtmlComponent = styled.div`
  h2 {
    font-size: ${fontSize(200)};
    font-weight: 500;
  }

  .btn {
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    border-radius: ${variable('border-radius')};

    &,
    &:link,
    &:visited {
      background-color: ${buttonColor};
      border-color: ${buttonColor};
      color: ${fontColor};
    }

    &:active,
    &:focus,
    &:hover {
      background-color: ${hoverColor};
      border-color: ${hoverColor};
      color: ${fontColor};
    }
  }

  .category-links {
    .title {
      text-align: left;
    }

    .links {
      .checklist {
        list-style: none;
        padding-left: 0px;

        .brand-link {
          text-align: left;
        }
      }
    }
  }

  .custom-categories {
    text-align: center;
    margin-bottom: ${spacer(125)};

    .category-title {
      font-size: ${fontSize(150)};
      margin-bottom: ${spacer(100)};
    }

    .custom-category-overview {
      display: flex;
      flex-wrap: wrap;

      .custom-category {
        padding-right: ${spacer(75)};
        padding-left: ${spacer(25)};
        margin-bottom: ${spacer(100)};

        ${breakpointUp(
          BREAKPOINTS.MD,
          css`
            width: 50%;
          `,
        )}

        ${breakpointUp(
          BREAKPOINTS.LG,
          css`
            width: calc(100% / 3);
          `,
        )}

        ${breakpointUp(
          BREAKPOINTS.XL,
          css`
            width: 25%;
          `,
        )}

        &-content {
          ${variable('border')}
          background-color: ${color('whisper-100')};
          height: 100%;

          .category-image {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .category-links {
            padding: ${spacer(75)};

            .title {
              font-size: ${fontSize(125)};

              .brand-link {
                text-decoration: none;
              }
            }

            .links {
              padding-top: ${spacer(75)};

              p {
                text-align: left !important;
              }
            }
          }
        }
      }
    }
  }
`;
