import styled from 'styled-components';
import { spacer } from 'theme';

export const FaqGroupOverviewList = styled.ul`
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: ${spacer(50)};
  margin-bottom: 0px;
`;
