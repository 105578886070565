import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import {
  breakpointDown,
  breakpointOnly,
  breakpointUp,
  color,
  fontFamily,
  fontSize,
  spacer,
  specific,
  variable,
} from 'theme';

export const StyledMasonry = styled.div`
  .c-masonry {
    padding: 10px 0;
    margin: 0 -10px;
    width: calc(100% + 20px);
    display: block;

    &__item {
      box-sizing: border-box;
      width: 100%;

      .o-box {
        margin: 10px;
        position: relative;
        overflow: hidden;
      }
    }

    .grid-item.one-by-one:not(.grouped-products) {
      width: 100% !important;
    }

    .grid-item-content {
      height: 340px !important;

      .product-link {
        height: 100%;
      }
    }

    &--halfx2 {
      .o-box {
        height: 160px;
      }

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          width: 50%;
        `,
      )}

      ${breakpointUp(
        BREAKPOINTS.LG,
        css`
          width: 50%;
        `,
      )}
    }

    &--1x2 {
      .o-box {
        height: 340px !important;
      }

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          width: 50%;
        `,
      )}

      ${breakpointUp(
        BREAKPOINTS.LG,
        css`
          width: 50%;
        `,
      )}
    }

    &-sizer,
    &--1x1 {
      width: 25% !important;

      &.image-tile {
        .o-box {
          padding-bottom: calc(100% - 20px);
          box-shadow: ${variable('box-shadow')};

          a {
            width: 100%;
            height: 100%;
            position: absolute;

            .masonry-content-tile {
              width: 100%;
              height: 100%;
              position: relative;

              .tile-image {
                height: 100%;
                width: 100%;
                position: relative;

                &.with-border {
                  border: solid 1px ${color('whisper-100')};
                  border-radius: 2px;
                }
              }

              &__titles {
                position: absolute;
                text-align: left;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 10px 5px 5px;
                height: 35%;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: ${specific('cms', 'masonry-tile-background-color')};
                color: ${specific('cms', 'masonry-tile-foreground-color')};

                h2,
                h3 {
                  flex: 0 0 auto;
                  padding: 4px 2px;
                }

                h2 {
                  font-size: ${fontSize(100)};
                  font-weight: 600;
                  margin: -3px 0px 0px;
                }

                h3 {
                  font-size: ${fontSize(75)};
                  font-weight: 400;
                  margin-bottom: -10px;
                  max-width: 80%;

                  ${breakpointUp(
                    BREAKPOINTS.XL,
                    css`
                      font-size: 14px;
                    `,
                  )}

                  ${breakpointOnly(
                    BREAKPOINTS.MD,
                    css`
                      font-size: 14px;
                      margin-bottom: 0;
                    `,
                  )}
                }

                .arrow-button {
                  display: block;
                  height: 26px;
                  width: 26px;
                  background-color: ${specific('cms', 'masonry-button-color')};
                  position: absolute;
                  bottom: 15px;
                  right: 15px;
                  border-radius: ${variable('border-radius')};
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  ${breakpointUp(
                    BREAKPOINTS.MD,
                    css`
                      height: 30px;
                      width: 30px;
                    `,
                  )}

                  ${breakpointUp(
                    BREAKPOINTS.XL,
                    css`
                      height: 35px;
                      width: 35px;
                    `,
                  )}
                }
              }
            }
          }
        }
      }

      ${breakpointUp(
        BREAKPOINTS.XS,
        css`
          width: 50% !important;
        `,
      )}

      ${breakpointUp(
        BREAKPOINTS.SM,
        css`
          width: 25% !important;
        `,
      )}
    }

    &--1x1 {
      &.image-tile {
        .o-box {
          margin: 10px;

          .tile-image {
            background-size: cover !important;
          }
        }
      }

      &.product {
        ${breakpointDown(
          BREAKPOINTS.SM,
          css`
            .one-by-one {
              height: 250px !important;

              .grid-item-content {
                height: 100% !important;
              }

              .discount-wrap {
                .discount {
                  right: 0;
                  padding: 0;
                }
              }

              .top-content {
                .logo {
                  margin-top: 5px;
                  margin-bottom: 0;
                  width: calc(100% - 60px);
                }

                h3 {
                  font-size: ${fontSize(75)};
                  padding-top: 5px;
                }

                .product-image-wrap {
                  height: 50%;
                }

                .old-price,
                .current-price,
                .strikethrough {
                  &::before {
                    font-size: 10px;
                  }

                  span {
                    font-size: ${fontSize(75)};
                  }
                }
              }
            }
          `,
        )}
      }

      ${breakpointDown(
        BREAKPOINTS.SM,
        css`
          width: 50% !important;
        `,
      )}

      &:not(.image-tile):not(.product):not(.category) {
        ${breakpointDown(
          BREAKPOINTS.SM,
          css`
            width: 100% !important;
          `,
        )}
      }
    }

    &--2x1 {
      .o-box {
        height: 700px;
      }

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          width: 50%;
        `,
      )}

      ${breakpointUp(
        BREAKPOINTS.LG,
        css`
          width: 25%;
        `,
      )}
    }

    .TextTop {
      .o-box {
        padding-top: 90px;

        width: calc(100% - 20px);
        left: 0;
      }

      .o-box__content {
        background: #fff;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        z-index: 1;
        padding: 15px;
      }

      .o-box__img {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      ${breakpointDown(
        BREAKPOINTS.LG,
        css`
          width: 50% !important;

          .o-box {
            display: flex;
            flex-direction: row;
            padding: 0;
            height: 340px !important;

            .o-box__content {
              width: 50%;
              position: relative;
              top: unset;
              left: unset;
              padding: 15px;

              h4 {
                font-size: 18px;
              }

              p {
                font-size: ${fontSize(75)};
              }
            }

            a:not(.btn) {
              display: block;
              width: 50%;

              .o-box__img {
                object-fit: cover;
                position: relative;
                bottom: unset;
                left: unset;
                height: 100%;
              }
            }
          }
        `,
      )}

      ${breakpointDown(
        BREAKPOINTS.SM,
        css`
          width: 100% !important;

          .o-box {
            margin-bottom: 0;
            height: 235px !important;
          }
        `,
      )}


    ${breakpointDown(
        BREAKPOINTS.XS,
        css`
          .o-box {
            height: 305px !important;
          }
        `,
      )}
    }

    .TextBottom {
      .o-box {
        padding-bottom: 90px;

        width: calc(100% - 20px);
        left: 0;

        @media (max-width: 739px) {
          margin: 10px 10px 0;
        }
      }

      .o-box__content {
        background: ${color('whisper-100')};
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        padding: ${spacer(100)};
        border: ${variable('border')};
      }

      ${breakpointDown(
        BREAKPOINTS.SM,
        css`
          height: 250px;
          display: flex;
          flex-direction: column;

          .o-box {
            padding-bottom: 0;
          }

          .o-box__img {
            max-height: 80px;
            object-fit: cover;
          }

          .o-box__content {
            position: relative;
            left: unset;
            bottom: unset;
            padding: 15px;
            height: calc(100% - 80px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h4 {
              font-size: 18px;
            }

            p {
              font-size: ${fontSize(75)};
            }

            .btn {
              margin-left: 0;
            }
          }
        `,
      )}
    }

    .onlyText {
      .o-box {
        background-color: ${color('whisper-100')};

        .o-box__content {
          display: flex;
          flex-direction: column;
          height: 100%;
          align-items: inherit;
          padding: ${spacer(100)};

          .o-box__title {
            width: 100%;
            margin-left: 0;
            font-weight: 200;
            line-height: 25px;
            font-size: ${fontSize(125)};

            ${breakpointDown(
              BREAKPOINTS.LG,
              css`
                margin-bottom: 10px;
              `,
            )}
          }

          .description {
            height: 40px;
            overflow: hidden;
            font-weight: 200;
            font-size: ${fontSize(75)};

            p {
              margin-bottom: 5px;
            }
          }

          .bottom-content {
            display: flex;
            width: 100%;
            justify-content: space-between;
            min-height: calc(100% - 65px);

            p {
              display: flex;
              margin-bottom: 0;
              font-weight: 200;
              font-size: ${fontSize(125)};

              ${breakpointDown(
                BREAKPOINTS.XL,
                css`
                  margin-right: 5px;
                `,
              )}

              span {
                margin: auto 0;

                ${breakpointDown(
                  BREAKPOINTS.XS,
                  css`
                    margin: 0;
                  `,
                )}
              }
            }

            ${breakpointDown(
              BREAKPOINTS.MD,
              css`
                .btn {
                  span {
                    padding: 0;
                  }
                }
              `,
            )}
          }

          ${breakpointDown(
            BREAKPOINTS.XS,
            css`
              padding: 15px;
            `,
          )}
        }
      }
    }

    .TextRight {
      .o-box {
        display: flex;
        flex-direction: row;
        background-color: ${color('whisper-100')};

        .top-content {
          display: flex;

          .wrapped-image {
            width: 40%;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: auto;
            margin-right: 15px;

            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              font-family: 'object-fit: contain';
            }

            ${breakpointDown(
              BREAKPOINTS.XL,
              css`
                width: 50%;
              `,
            )}

            ${breakpointDown(
              BREAKPOINTS.XS,
              css`
                margin: auto;
                width: 40%;
              `,
            )}
          }

          .description {
            justify-content: space-evenly;
            display: flex;
            flex-direction: column;
            font-weight: 200;

            .o-box__title {
              width: 100%;
              font-weight: 200;

              ${breakpointDown(
                BREAKPOINTS.XL,
                css`
                  font-size: ${fontSize(125)};
                `,
              )}

              ${breakpointDown(
                BREAKPOINTS.XS,
                css`
                  margin-top: 15px;
                `,
              )}
            }
          }

          ${breakpointDown(
            BREAKPOINTS.XS,
            css`
              flex-direction: column;
              height: 75%;
            `,
          )}
        }

        .o-box__content {
          flex-direction: column;
          display: flex;
          position: relative;

          .bottom-content {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 25px;

            p {
              width: 100%;
              margin-bottom: 0;
              display: block;
              align-items: center;
              margin-right: 5px;
              font-weight: 200;

              span {
                height: 100%;
                align-items: center;
                display: inline-flex;
              }
            }
          }

          ${breakpointDown(
            BREAKPOINTS.LG,
            css`
              .top-content {
                .description-text {
                  p {
                    margin-bottom: 0;
                    max-height: 160px;
                    overflow: hidden;
                  }
                }
              }
            `,
          )}

          ${breakpointDown(
            BREAKPOINTS.MD,
            css`
              .top-content {
                padding: 0;

                .description-text {
                  height: 155px;
                  overflow: hidden;
                }
              }
            `,
          )}



          ${breakpointDown(
            BREAKPOINTS.XS,
            css`
              .top-content {
                .description-text {
                  height: 80px;

                  p {
                    font-size: ${fontSize(75)};
                    height: 100%;
                    align-items: center;
                    display: inline-flex;
                  }
                }
              }

              .bottom-content {
                padding: 15px;

                span {
                  font-size: ${fontSize(75)};
                  display: initial;
                }

                .btn {
                  font-size: ${fontSize(75)};
                }
              }

              padding: 15px;
            `,
          )}
        }
      }
    }

    .products-slider {
      width: 100%;

      .search-product-slider {
        margin: 0;

        .products-overview {
          .product {
            .grid-item-content {
              border: none;
              padding: 0;
              height: 100% !important;
            }
          }
        }
      }
    }

    .content-block-slider {
      height: 360px;

      .slick-slider {
        height: 100%;

        .slick-list {
          height: 100%;

          .slick-track {
            height: 100%;

            .slick-slide {
              height: 100%;

              > div {
                max-height: 100%;

                .c-masonry__item {
                  width: 100% !important;
                  height: 100%;

                  .o-box {
                    margin: 0;
                    border: none;
                    width: 100%;
                    height: 340px;
                    background-color: ${color('whiper-100')};
                    padding: 15px;
                    display: flex;
                    flex-direction: row;

                    &__content {
                      background-color: ${color('whiper-100')};
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      text-align: center;

                      .o-box__title {
                        padding-bottom: 15px;
                        border-bottom: 1px solid ${color('whisper-100')};
                        width: 100%;
                      }

                      p {
                        font-style: italic;
                        margin-bottom: 10px;

                        ${breakpointDown(
                          BREAKPOINTS.XS,
                          css`
                            text-overflow: ellipsis;
                            width: 100%;
                          `,
                        )}
                      }

                      .btn {
                        margin: 0;

                        ${breakpointDown(
                          BREAKPOINTS.MD,
                          css`
                            font-size: 10px;
                            display: block;
                            width: 100%;
                          `,
                        )}
                      }
                    }

                    &__border {
                      border: none;
                    }

                    ${breakpointDown(
                      BREAKPOINTS.MD,
                      css`
                        height: 235px;
                      `,
                    )}
                  }

                  &.TextTop {
                    .o-box__content {
                      background-color: ${color('whisper-100')};
                      position: relative;
                      width: 50%;
                      height: 100%;
                    }

                    a {
                      width: 50%;
                      position: relative;
                    }
                  }

                  &.TextBottom {
                    a:first-child {
                      width: 100%;
                      position: relative;
                    }

                    ${breakpointDown(
                      BREAKPOINTS.SM,
                      css`
                        a {
                          width: 50%;
                          height: 100%;
                          align-items: center;
                          display: flex;
                        }

                        .o-box__content {
                          width: 50%;
                          height: 100%;
                          position: relative;
                          bottom: unset;
                          left: unset;

                          .btn {
                            height: initial;
                          }
                        }
                      `,
                    )}
                  }
                }
              }
            }
          }
        }
      }

      ${breakpointDown(
        BREAKPOINTS.SM,
        css`
          height: 250px;

          .o-box {
            height: 235px !important;
          }
        `,
      )}

      .o-box {
        border: none;
      }
    }

    @media (max-width: 739px) {
      padding-top: 0;

      & + .c-masonry {
        padding-bottom: 10px;
      }
    }
  }

  .masonry-title {
    width: 100%;
  }

  .masonry {
    &.centered {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    ${breakpointUp(
      BREAKPOINTS.MD,
      css`
        &.webexclusive {
          .grid-item.two-by-one {
            height: 200px;

            .content-wrap {
              align-items: center;

              .h-style-2 {
                font-weight: 400;
                font-size: 18px;
                margin-right: 10px;
              }

              .mt-md {
                font-weight: 200;
                font-size: ${fontSize(75)};
                margin-top: 10px;
              }
            }
          }
        }
      `,
    )}
  }

  .product-link {
    height: 100%;

    &:hover {
      color: ${color('black')};
    }
  }

  img {
    object-fit: contain;
  }

  .top-content {
    display: flex;
    flex-direction: column;
    gap: ${spacer(50)};
  }

  .price-wrap {
    align-items: baseline;
    background: ${color('shisper-100')};
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    line-height: 1;
    padding: 10px 15px;
    position: absolute;
    width: 100%;
  }

  .promotion-wrap {
    display: none;
    position: absolute;
    bottom: 52px;
    left: 0;
    right: 0;
    padding: ${spacer(50)};
    text-align: right;
    background-color: ${color('whisper-transparent-200')};

    ${breakpointUp(
      BREAKPOINTS.SM,
      css`
        display: unset;
      `,
    )}
  }

  .product-image-wrap {
    position: relative;
    align-items: stretch;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 75%;
    margin: auto;
    padding-bottom: 52px;
    height: 50%;
  }

  .product-title {
    color: ${specific('generic', 'body-color')};
    font-size: ${fontSize(100)};
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.folder-section {
    .grid-item-content {
      height: unset;

      &.intro_with_button {
        height: 100%;
      }

      .content-wrap {
        ${breakpointUp(
          BREAKPOINTS.MD,
          css`
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          `,
        )}
      }
    }

    .folder-slider {
      padding: 0;
      margin-left: auto;
    }
  }

  .title-component {
    font-family: ${fontFamily('secondary')};
    font-size: ${fontSize(150)};
    font-weight: 600;
    padding: 0px 10px;
    margin-top: 30px;
  }

  .grid-item {
    height: 100%;
    padding: 10px;

    .grid-item-content {
      height: 20vw;
      border-radius: ${variable('border-radius')};
      overflow: hidden;
      position: relative;

      .signing-wrap {
        position: absolute;
        right: 0;
      }

      &.border {
        &-grey {
          border: 1px solid ${color('whisper-100')};
        }

        &-orange {
          border: 1px solid ${color('secondary-300')};
        }

        &-whisper {
          ${variable('border')}
        }
      }
    }

    &.product-w-price {
      position: relative;

      &.discount-tag {
        .logo,
        .product-title {
          width: 100%;

          ${breakpointUp(
            BREAKPOINTS.SM,
            css`
              width: 85%;
            `,
          )}
        }
      }

      .top-content,
      .content-wrap {
        height: 100%;
        padding: ${spacer(75)};
        position: relative;

        ${breakpointUp(
          BREAKPOINTS.MD,
          css`
            padding: ${spacer(100)};
          `,
        )}

        .logo {
          height: 20px;
          margin-bottom: 0px;

          img {
            display: block;
          }

          span {
            font-size: ${fontSize(100)};
            text-transform: uppercase;
            color: ${color('whisper-400')};
            display: inline-block;
            font-weight: bold;
            white-space: nowrap;
            margin-top: 0;
          }
        }

        .sponsored {
          color: ${color('whisper-200')};
          font-size: ${fontSize(100)};
          margin-bottom: 0;
          margin-top: 10px;
        }

        .price-wrap {
          .old-price {
            .striketrough {
              display: block;
            }
          }

          .current-price {
            &.orange-bg {
              text-shadow: none;
            }
          }

          ${breakpointUp(
            BREAKPOINTS.SM,
            css`
              bottom: 0;

              .old-price {
                padding-right: 0;
              }

              .cashback-wrap {
                top: 10px;
                padding: 3px 0;

                .promotion-wrap {
                  width: 100%;
                  justify-content: flex-end;
                  margin: -15px 0 -3px;
                  padding: 3px 10px;

                  .price,
                  .text {
                    padding: 0;
                  }

                  .text {
                    font-size: 10px;
                  }

                  .price {
                    font-size: ${fontSize(75)};

                    &::before {
                      font-size: 10px;
                    }
                  }
                }

                &.only-text {
                  .text {
                    font-size: ${fontSize(75)};
                    margin-top: 0;
                  }
                }
              }
            `,
          )}

          ${breakpointUp(
            BREAKPOINTS.XS,
            css`
              .old-price,
              .current-price,
              .strikethrough {
                &::before,
                &::after {
                  font-size: 10px;
                }

                font-size: ${fontSize(75)};
              }

              .cashback-wrap {
                top: 5px;
              }
            `,
          )}
        }
      }
    }
  }

  &.webexclusive {
    .grid-item {
      &.two-by-one {
        height: 340px;

        .grid-item-content {
          height: 100%;
        }
      }
    }

    .product-w-price.webexclusive {
      height: 340px;

      .grid-item-content {
        height: 100%;

        .top-content {
          height: 100%;

          .product-image-wrap {
            display: flex;
            justify-content: center;
            height: calc(100% - 80px);
            width: 100%;
          }
        }
      }
    }
  }

  ${breakpointUp(
    BREAKPOINTS.XXL,
    css`
      .grid-item {
        &.one-by-one {
          width: calc(100% / 4);

          .grid-item-content {
            height: calc((100vw - 145px) / 4);
          }
        }

        &.two-by-one {
          width: 50%;

          .grid-item-content {
            height: calc((100vw - 145px) / 4);
          }
        }
      }
    `,
  )}

  ${breakpointUp(
    BREAKPOINTS.XL,
    css`
      .grid-item {
        &.one-by-one {
          width: calc(100% / 4);

          .grid-item-content {
            height: calc((100vw - 145px) / 4);
          }
        }
      }
    `,
  )}

  ${breakpointUp(
    BREAKPOINTS.XL,
    css`
      .grid-item {
        .grid-item-content {
          height: calc(100vw / 3);
        }

        &.one-by-one {
          width: calc(100% / 3);

          .grid-item-content {
            height: calc((100vw - 145px) / 3);
          }
        }

        &.two-by-one {
          width: calc(2 * 100% / 3);

          .content-wrap.vert-center {
            display: flex;
            flex-wrap: wrap;

            a.mt-md {
              margin-top: 0;
            }
          }
        }

        &.two-by-two {
          width: calc(2 * 100% / 3);
        }

        &.discount-tag {
          .discount {
            top: 0;
            right: 1px;
          }
        }
      }
    `,
  )}

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      .grid-item {
        &.one-by-one {
          width: calc(100% / 2);

          .grid-item-content {
            height: calc((100vw - 145px) / 2);
          }
        }

        &.two-by-one {
          width: 100%;

          .grid-item-content {
            height: calc((100vw - 145px) / 2);
          }
        }
      }
    `,
  )}

  ${breakpointUp(
    BREAKPOINTS.SM,
    css`
      .grid-item {
        &.two-by-one,
        &.one-by-one {
          height: 350px;

          .grid-item-content {
            height: 100%;
          }

          &.webexclusive {
            height: 250px !important;
          }
        }

        &.one-by-two,
        &.two-by-two {
          width: 100%;
          height: 450px;

          .grid-item-content {
            height: 100%;
          }
        }

        &.img-with-text {
          &.two-by-one {
            .grid-item-content {
              .info {
                .text-wrap {
                  width: 100%;
                }
              }
            }
          }

          .grid-item-content {
            .info {
              padding: 15px;
              padding-bottom: 10px;

              .h-style-6 {
                font-size: 18px;
              }

              .btn {
                width: 100%;
              }
            }
          }
        }

        &.delivery {
          height: 560px;
        }

        .grid-item-content {
          &.intro-with-button {
            &.with-image {
              .content-wrap {
                .left-content {
                  .h-style-2 {
                    font-size: ${fontSize(150)};
                  }

                  width: 100%;

                  .left-content-wrap {
                    &.vert-center {
                      position: static;
                      transform: none;
                    }
                  }
                }

                .right-content {
                  display: none;
                }
              }
            }
          }
        }
      }
    `,
  )}

  ${breakpointUp(
    BREAKPOINTS.XS,
    css`
      .grid-item {
        .grid-item-content {
          &.intro-with-button {
            .content-wrap {
              text-align: center;
            }
          }
        }
      }
    `,
  )}
`;
