/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { Image as ImageType } from 'types/Image';
import { Product } from 'types/Product';
import { cn } from 'utils/cn';
import Award from '../../General/Award/Award';
import Signing from '../../General/Signing/Signing';
import Image from '../../Image/Image';
import Price from '../../Price/Price';
import ProductLink from '../../Router/Link/ProductLink/ProductLink';

import { StyledEnergyLabel } from './ProductTile.styled';

interface ProductTileProps {
  className?: string;
  isProductDeal?: boolean;
  product: Product;
  productImage?: ImageType;
}

/**
 * @deprecated
 * Use ProductCard instead
 */
const ProductTile = ({ className, isProductDeal = false, product, productImage }: ProductTileProps) => {
  const hasContentPage = 'page' in product;
  const currentImage = productImage?.url ? productImage : product?.image;

  useEffect(() => {
    window.productOverview?.push(product);
  }, []);

  return (
    <div className={cn('product-w-price discount-tag', className, isProductDeal ? 'webexclusive product-deal' : '')}>
      <div className="grid-item-content border-whisper">
        {/* Promo signing */}
        {product.signing && (
          <div className="signing-wrap">
            <Signing signing={product.signing} />
          </div>
        )}

        {product && product.award && <Award award={product.award} />}

        {!hasContentPage && (
          <ProductLink
            atp={product?.atp}
            brand={product?.brand}
            carrier={product?.carrier}
            code={product?.code}
            dataLayerCategoryPath={product?.dataLayerCategoryPath}
            name={product?.name}
            price={product?.price}
            quantity={product?.quantity}
            url={product?.url}
            tracking={product?.tracking}
          >
            <div className="top-content">
              {/* Brand logo */}
              {product.brand && (
                <div className="logo">
                  {product.brand.image && product.brand.image.url ? (
                    <Image
                      alt={`Product brand image for ${product.brand.name}`}
                      height={20}
                      src={product.brand.image.url}
                      width={50}
                    />
                  ) : (
                    <span>{product.brand.name}</span>
                  )}
                </div>
              )}

              <h3 className="product-title">{product.name}</h3>

              {/* Product image */}
              {currentImage && currentImage.url && (
                <div className="product-image-wrap">
                  <Image
                    alt={`${product?.brand?.name} ${product?.name}`}
                    layout="fill"
                    quality={100}
                    src={currentImage.url}
                  />
                </div>
              )}
              <StyledEnergyLabel energyLabel={product?.energyLabel} flipHorizontal />
              {/* Product price */}
              {!!product.price?.value && (
                <Price
                  currentPrice={product?.price?.value}
                  oldPrice={product?.strikePrice?.value}
                  oldPriceFontSize={100}
                />
              )}
            </div>
          </ProductLink>
        )}
      </div>
    </div>
  );
};

export default ProductTile;
