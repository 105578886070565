import Image from 'components/Image/Image';
import { CMSButton } from 'types/CMS';
import { Image as ImageType } from 'types/Image';
import Link from '../../../../../../Link/Link';

type CMSContentBlockFolderTemplateProps = {
  button?: CMSButton;
  image?: ImageType;
  index: number;
  text?: string[];
  title?: string;
};

/**
 * @param {number} index - The index of the component.
 * @param {object} button - The button object containing the text and url.
 * @param {Array} text - The checkmark list items.
 * @param {string} title - The title of the component.
 * @param {string} image - The image url.
 */
const CMSContentBlockFolderTemplate = ({
  button,
  image,
  index,
  text = [],
  title,
}: CMSContentBlockFolderTemplateProps) => {
  const placementClass = index % 2 === 0 ? 'left-part' : 'right-part';

  return (
    <div className={`${placementClass} part with-image lightblue-with-text vertical-align`}>
      <div className="content-wrap">
        {!!button?.url && !!image?.url && (
          <Link url={button.url}>
            <div className="image-wrap folder">
              <Image alt={title} src={image.url} />
            </div>
          </Link>
        )}
        <div className="pl-s text-wrap">
          <h3 className="title radikal h-style-1 pb-s">{title}</h3>
          {text &&
            text.map((line, lineIndex) => (
              <div key={lineIndex} className="radikal text-large" dangerouslySetInnerHTML={{ __html: line }} />
            ))}
          {button && !button.isExternal && <Link url={button.url}>{button.text}</Link>}
          {button && button.isExternal && (
            <Link newTab url={button.url}>
              {button.text}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default CMSContentBlockFolderTemplate;
