import CMSContentBlockDemoDayTemplate from './CMSContentBlockDemoDayTemplate/CMSContentBlockDemoDayTemplate';
import CMSContentBlockEcoChequeTemplate from './CMSContentBlockEcoChequeTemplate/CMSContentBlockEcoChequeTemplate';
import CMSContentBlockFolderTemplate from './CMSContentBlockFolderTemplate/CMSContentBlockFolderTemplate';
import CMSContentBlockStorefinderTemplate from './CMSContentBlockStorefinderTemplate/CMSContentBlockStorefinderTemplate';
import CMSContentBlockUSPTemplate from './CMSContentBlockUSPTemplate/CMSContentBlockUSPTemplate';

const templates = {
  DEMODAY_CONTENT_BLOCK: CMSContentBlockDemoDayTemplate,
  ECOCHEQUE_CONTENT_BLOCK: CMSContentBlockEcoChequeTemplate,
  FOLDER_CONTENT_BLOCK: CMSContentBlockFolderTemplate,
  STOREFINDER_CONTENT_BLOCK: CMSContentBlockStorefinderTemplate,
  USP_CONTENT_BLOCK: CMSContentBlockUSPTemplate,
};

export default templates;
