// @ts-nocheck => CMS components will eventually be replaced by CF components so no need to type them
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import CMSMasonryContentTileData from '../CMSMasonryContentTileData/CMSMasonryContentTileData';
import { CmsContentTileSliderComponent } from './CMSContentTileSlider.styled';

const CMSContentTileSlider = ({ ...cmsComponent }) => {
  if (!cmsComponent?.tiles?.length) {
    return null;
  }

  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.5,
    [BREAKPOINTS.SM]: 2.5,
  };

  return (
    <CmsContentTileSliderComponent slidesToShow={slidesToShow}>
      {cmsComponent?.tiles.map((tile) => (
        <div key={tile?.title}>
          <CMSMasonryContentTileData {...tile} isSliderTile />
        </div>
      ))}
    </CmsContentTileSliderComponent>
  );
};

export default CMSContentTileSlider;
