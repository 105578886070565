import { ProductCard } from 'components/Product/ProductCard';
import useProductCard from 'hooks/useProductCard';
import { CMSContentBlock } from 'types/CMS';
import { Product } from 'types/Product';
import Button from '../../../../../../Button/Button';
import Link from '../../../../../../Link/Link';
import { StyledMasonry } from '../../../CMSMasonryComponents.styled';

type CMSProductTilesWebExclusiveTemplateProps = {
  centered?: boolean;
  contentBlock?: CMSContentBlock;
  products?: Product[];
};

const CMSProductTilesWebExclusiveTemplate = ({
  centered,
  contentBlock,
  products,
}: CMSProductTilesWebExclusiveTemplateProps) => {
  const webExclusiveProducts =
    products &&
    products.filter(
      (product) =>
        product.isWebExclusive && product.webExclusiveEndDate && new Date(product.webExclusiveEndDate) > new Date(),
    );

  const { mapProductCardProps } = useProductCard();

  if (webExclusiveProducts && webExclusiveProducts.length > 0) {
    return (
      <StyledMasonry className="container">
        <section className={`mgrid-1 masonry webexclusive grid ${centered ? 'centered' : ''}`}>
          {contentBlock && (
            <div className="grid-item two-by-one">
              <div className="grid-item-content intro_with_button">
                <div className="fh grid md:grid-cols-12">
                  <div className="fh md:col-span-10 md:col-start-2">
                    <div className="content-wrap vert-center">
                      {contentBlock.title && (
                        <h1
                          dangerouslySetInnerHTML={{
                            __html: contentBlock.title,
                          }}
                          className="h-style-2"
                        />
                      )}

                      {contentBlock.text &&
                        contentBlock.text.length > 0 &&
                        contentBlock.text.map((line, index) => (
                          <div dangerouslySetInnerHTML={{ __html: line }} key={index} className="f-bold mt-md" />
                        ))}

                      {contentBlock.button && (
                        <Link url={contentBlock.button.url}>
                          <Button color="secondary">{contentBlock.button.text}</Button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {webExclusiveProducts.map((product, index) => {
            const productCardProps = mapProductCardProps({ product });
            return (
              <ProductCard key={`${product.code}-${index}`} className="grid-item one-by-one" {...productCardProps} />
            );
          })}
        </section>
      </StyledMasonry>
    );
  }

  return null;
};

export default CMSProductTilesWebExclusiveTemplate;
