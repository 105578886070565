import { CMSButton } from 'types/CMS';
import { Image } from 'types/Image';
import templates from './Template';

type CMSContentBlockComponentProps = {
  button?: CMSButton;
  image?: Image;
  index: number;
  template?: string;
  text?: string[];
  title?: string;
};

/**
 * The CMS CMSContentBlockComponent component template.
 *
 * @author janssco
 *
 * @param {number} index - The index of the component.
 * @param {string} template - The component template.
 * @param {object} button - The button object containing the text and url.
 * @param {Array} text - The checkmark list items.
 * @param {string} title - The title of the component.
 * @param {object} image - The image of the component.
 * @constructor
 */
const CMSContentBlockComponent = ({
  button,
  image,
  index,
  template,
  text = [],
  title,
}: CMSContentBlockComponentProps) => {
  if (template) {
    //@ts-ignore
    const Template = templates[`${template}_CONTENT_BLOCK`];

    if (Template) {
      return <Template index={index} button={button} text={text} title={title} image={image} />;
    }
  }

  return null;
};

export default CMSContentBlockComponent;
