import Image from 'components/Image/Image';
import { Image as ImageType } from 'types/Image';
import { BORDER_TEMPLATES } from '../../../../../constants/cms';
import { ICON_NAMES } from '../../../../../constants/icon';
import Icon from '../../../../Icon/Icon';
import Link from '../../../../Link/Link';
import { StyledMasonryTileData } from './CMSMasonryContentTileData.styled';

type CMSMasonryContentTileDataProps = {
  buttonText?: string;
  externalUrl?: string;
  image?: ImageType;
  isSliderTile?: boolean;
  qualifier?: string;
  subtitle?: string;
  template?: string;
  textPosition?: string;
  title?: string;
};

const CMSMasonryContentTileData = ({
  buttonText,
  externalUrl,
  image,
  isSliderTile,
  qualifier,
  subtitle,
  template,
  textPosition: textPositionProp,
  title,
}: CMSMasonryContentTileDataProps) => {
  const borderClass = template === BORDER_TEMPLATES.BORDERLESS ? '' : 'with-border';
  let linkNode = null;
  const textPosition = textPositionProp ? textPositionProp.toLowerCase().replace('_', '-') : 'left-top';
  const withText = title ? 'with-text' : '';

  if (qualifier || externalUrl) {
    let href;

    if (qualifier) {
      href = qualifier;
      if (qualifier.endsWith('-nl') || qualifier.endsWith('-fr')) {
        href = qualifier.substring(0, qualifier.lastIndexOf('-'));
      }
    } else if (externalUrl) {
      href = externalUrl;
    }

    linkNode = (
      <Link newTab={!!externalUrl} passHref url={href}>
        <div className="masonry-content-tile">
          {!!image?.url && (
            <div className={`tile-image ${borderClass} ${withText}`}>
              <Image
                alt={title}
                layout="fill"
                sizes={{
                  default: 300,
                }}
                src={image.url}
              />
            </div>
          )}

          {title && subtitle && (
            <div className={`masonry-content-tile__titles masonry-content-tile__titles--${textPosition}`}>
              <h2>{title}</h2>
              <h3>{subtitle}</h3>
              {!!buttonText && (
                <span className="arrow-button">
                  <Icon color="white" name={ICON_NAMES.CHEVRON} rotate={270} size={75} />
                </span>
              )}
            </div>
          )}
        </div>
      </Link>
    );
  } else {
    linkNode = (
      <div className="masonry-content-tile">
        <div className={`tile-image ${borderClass} ${withText}`}>
          {!!image?.url && <Image alt={title} layout="fill" src={image?.url} />}
        </div>{' '}
        {title && subtitle && (
          <div className={`masonry-content-tile__titles masonry-content-tile__titles--${textPosition}`}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
            {!!buttonText && (
              <span className="arrow-button">
                <Icon color="white" name={ICON_NAMES.CHEVRON} rotate={270} size={75} />
              </span>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <StyledMasonryTileData
      className={`c-masonry__item c-masonry--1x1 image-tile
        ${isSliderTile ? 'slider-tile' : ''}`}
      desktopOnly={!isSliderTile}
    >
      <div className="o-box">{linkNode}</div>
    </StyledMasonryTileData>
  );
};

export default CMSMasonryContentTileData;
