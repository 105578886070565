import { FaqGroup } from 'types/Faq';
import Flex from '../../../../../../Flex/Flex';
import FaqGroupDetail from './Detail/InfoGroupDetail/InfoGroupDetail';
import FaqGroupOverview from './Overview/InfoGroupOverview/InfoGroupOverview';

type CMSInfoContainerDataFullTemplateProps = { faqGroups: FaqGroup[] };

const CMSInfoContainerDataFullTemplate = ({ faqGroups }: CMSInfoContainerDataFullTemplateProps) => (
  <Flex flexDirection="column" gap={200}>
    {faqGroups && faqGroups.map((group) => <FaqGroupOverview key={`${group?.title}-overview`} group={group} />)}

    {faqGroups && faqGroups.map((group) => <FaqGroupDetail key={`${group?.title}-detail`} group={group} />)}
  </Flex>
);

export default CMSInfoContainerDataFullTemplate;
