import { FaqItem } from 'types/Faq';
import { getFaqQuestionSlug } from '../../../../../../../../../utils/slugUtil';
import Typography from '../../../../../../../../Typography/Typography';
import { FaqItemDetailHtml } from './InfoItemDetail.styled';

type InfoItemDetailProps = {
  item: FaqItem;
};
const InfoItemDetail = ({ item }: InfoItemDetailProps) => {
  if (!item) return null;

  const url = getFaqQuestionSlug(item);

  return (
    <div id={url ?? ''}>
      <Typography fontWeight="bold" type="p">
        {item.question}
      </Typography>
      <FaqItemDetailHtml html={item.answer} />
    </div>
  );
};

export default InfoItemDetail;
