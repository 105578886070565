import { FaqGroup } from 'types/Faq';
import Typography from '../../../../../../../../Typography/Typography';
import FaqItemDetail from '../InfoItemDetail/InfoItemDetail';

type InfoGroupDetailProps = {
  group: FaqGroup;
  withTitle?: boolean;
};

const InfoGroupDetail = ({ group, withTitle = true }: InfoGroupDetailProps) => (
  <article className="content-item">
    {withTitle && <Typography type="h2">{group?.title}</Typography>}
    {group?.faqItems?.map((faqItem) => <FaqItemDetail key={`${faqItem?.url}-item-detail`} item={faqItem} />)}
  </article>
);

export default InfoGroupDetail;
