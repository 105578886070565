import Icon from 'components/Icon/Icon';
import Link from 'components/Link/Link';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';

interface StyledTextSigningProps {
  backgroundColor?: string;
}

const SigningImageWrapperCss = css`
  position: relative;
  width: 30px;
  height: 30px;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      width: 50px;
      height: 50px;
    `,
  )};
`;

export const SigningImageWrapper = styled.div`
  ${SigningImageWrapperCss}
`;

export const SigningImageWrapperLink = styled(Link)`
  ${SigningImageWrapperCss}
`;

export const StyledTextSigning = styled.div<StyledTextSigningProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor && backgroundColor};
  border-radius: ${variable('border-radius-long')};
  padding: ${spacer(50)};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const StyledIcon = styled(Icon)`
  display: inline-flex;
`;
