import Button from 'components/Button/Button';
import Image from 'components//Image/Image';
import Link from 'components/Link/Link';
import Typography from 'components/Typography/Typography';
import { CMSButton } from 'types/CMS';
import { Image as ImageType } from 'types/Image';
import { useMemo } from 'react';

interface CMSMasonryContentBlock2x1TextBottomInterface {
  button: CMSButton;
  image: ImageType;
  text: string | string[];
  title: string;
}

const CMSMasonryContentBlock2x1TextBottom = ({
  button,
  image,
  text,
  title,
}: CMSMasonryContentBlock2x1TextBottomInterface) => {
  const wrappedImage = useMemo(() => {
    const imageComponent = image?.url && (
      <Image alt={title} className="o-box__img" layout="fill" objectFit="cover" quality={100} src={image.url} />
    );

    if (button?.url) {
      return (
        <Link url={button.url} newTab={button?.isExternal}>
          {imageComponent}
        </Link>
      );
    } else {
      return imageComponent;
    }
  }, [image, button]);

  return (
    <div className="c-masonry__item c-masonry--2x1 TextBottom">
      <div className="o-box o-box--border ">
        {wrappedImage}

        <div className="o-box__content">
          {title && (
            <Typography fontWeight="normal" type="h3">
              {title}
            </Typography>
          )}
          {text && <Typography type="p">{text}</Typography>}

          {button?.url && (
            <Link url={button.url}>
              <Button color="secondary">{button.text}</Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default CMSMasonryContentBlock2x1TextBottom;
