import styled, { css } from 'styled-components';
import { breakpointUp, spacer, color, variable } from 'theme';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import Icon from '../../../../Icon/Icon';
import NavList from '../../../../Nav/NavList/NavList';

interface ShowProps {
  show?: boolean;
}

export const StyledCmsFooterComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: ${spacer(75)} 0px ${spacer(75)} ${spacer(75)};
  background-color: ${color('whisper-200')};
  ${variable('border-bottom')};
  border-bottom-color: ${color('white')};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      background-color: unset;
      padding-left: 0px;
    `
  )};
`;

export const StyledNavList = styled(NavList)<ShowProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${spacer(50)};
  padding-left: ${spacer(100)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
      padding-left: 0px;
    `
  )}
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      cursor: default;
    `
  )}
`;

export const StyledTitleIcon = styled(Icon)`
  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: none;
    `
  )}
`;
