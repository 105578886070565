import { useWebConfig } from 'features/configuration/queries';
import { LayoutType } from 'types/Image';
import Image from '../Image/Image';
import { CategoryTileImageWrapper, CategoryTileWrapper, StyledCategoryName } from './CategoryTile.styled';

interface CategoryTileProps {
  height: number;
  layout?: LayoutType;
  name?: string;
  src?: string;
  width: number;
}

const CategoryTile = ({ height, layout, name, src, width }: CategoryTileProps) => {
  const { data: webConfig } = useWebConfig();

  const imageQuality = webConfig?.imageQuality;

  return (
    <CategoryTileWrapper>
      {src && (
        <CategoryTileImageWrapper>
          <Image alt={name} height={height} layout={layout} quality={imageQuality} src={src} width={width} />
        </CategoryTileImageWrapper>
      )}

      <StyledCategoryName fontSize={100} fontWeight="bold" marginTop={100} tag="h2" textAlign="center">
        {name}
      </StyledCategoryName>
    </CategoryTileWrapper>
  );
};

export default CategoryTile;
