import CMSMasonryContentBlock1x1TextTop from './CMSMasonryContentBlock1x1TextTop/CMSMasonryContentBlock1x1TextTop';
import CMSMasonryContentBlock1x1TextBottom from './CMSMasonryContentBlock1x1TextBottom/CMSMasonryContentBlock1x1TextBottom';
import CMSMasonryContentBlock1x2TextTop from './CMSMasonryContentBlock1x2TextTop/CMSMasonryContentBlock1x2TextTop';
import CMSMasonryContentBlock1x2TextBottom from './CMSMasonryContentBlock1x2TextBottom/CMSMasonryContentBlock1x2TextBottom';
import CMSMasonryContentBlock2x1TextTop from './CMSMasonryContentBlock2x1TextTop/CMSMasonryContentBlock2x1TextTop';
import CMSMasonryContentBlock2x1TextBottom from './CMSMasonryContentBlock2x1TextBottom/CMSMasonryContentBlock2x1TextBottom';

const templates = {
  FORMAT_1X1_TEXT_BOTTOM: CMSMasonryContentBlock1x1TextBottom,
  FORMAT_1X1_TEXT_TOP: CMSMasonryContentBlock1x1TextTop,
  FORMAT_1X2_TEXT_BOTTOM: CMSMasonryContentBlock1x2TextBottom,
  FORMAT_1X2_TEXT_TOP: CMSMasonryContentBlock1x2TextTop,
  FORMAT_2X1_TEXT_BOTTOM: CMSMasonryContentBlock2x1TextBottom,
  FORMAT_2X1_TEXT_TOP: CMSMasonryContentBlock2x1TextTop,
};

export default templates;
