import Typography from 'components/Typography/Typography';
import { useState } from 'react';
import { FooterBottomLink } from 'types/Footer';
import { ICON_NAMES } from '../../../../../constants/icon';
import Link from '../../../../Link/Link';
import NavListItem from '../../../../Nav/NavList/NavListItem/NavListItem';
import {
  StyledCmsFooterComponent,
  StyledNavList,
  StyledTitleIcon,
  StyledTitleWrapper,
} from './CMSFooterComponent.styled';

interface CMSFooterComponentProps {
  linkComponents?: FooterBottomLink[];
  title?: string;
}

const CMSFooterComponent = ({ linkComponents, title }: CMSFooterComponentProps) => {
  const [isOpen, setOpen] = useState(false);

  if (!linkComponents?.length) {
    return null;
  }

  return (
    <StyledCmsFooterComponent>
      {title && (
        <StyledTitleWrapper onClick={() => setOpen(!isOpen)}>
          <Typography type="h3" marginBottom={0}>
            {title}
          </Typography>
          <StyledTitleIcon type="fa" name={isOpen ? ICON_NAMES.ANGLE_DOWN : ICON_NAMES.ANGLE_RIGHT} size={125} />
        </StyledTitleWrapper>
      )}
      <StyledNavList show={isOpen}>
        {linkComponents.map((linkComponent) => (
          <NavListItem key={`${title}_${linkComponent.localizedName}`} shouldScrollToTop>
            <Link newTab={!!linkComponent?.externalUrl} url={linkComponent?.externalUrl || linkComponent?.url}>
              {linkComponent.localizedName}
            </Link>
          </NavListItem>
        ))}
      </StyledNavList>
    </StyledCmsFooterComponent>
  );
};

export default CMSFooterComponent;
