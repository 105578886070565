import Typography from '../../../../Typography/Typography';

type CMSMasonryTitleComponentProps = { title?: string };

const CMSMasonryTitleComponent = ({ title }: CMSMasonryTitleComponentProps) =>
  !!title && (
    <Typography className="masonry-title" fontWeight="bold" marginBottom={0} type="h3">
      {title}
    </Typography>
  );

export default CMSMasonryTitleComponent;
