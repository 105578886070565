import { ReactNode } from 'react';
import { ComponentBannerContainer } from './CMSSlotComponentBannerTemplate.styled';

type CMSSlotComponentBannerTemplateProps = {
  children?: ReactNode;
};

const CMSSlotComponentBannerTemplate = ({ children, ...props }: CMSSlotComponentBannerTemplateProps) => (
  <ComponentBannerContainer>
    <div className="container">
      <section className="component-banner two-parts seperated-with-border" {...props}>
        {children}
      </section>
    </div>
  </ComponentBannerContainer>
);

export default CMSSlotComponentBannerTemplate;
