import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme';
import { BREAKPOINTS } from 'constants/breakpoints';
import Link from 'components/Link/Link';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';

const bannerHeight = css`
  ${breakpointUp(
    BREAKPOINTS.SM,
    css`
      height: 300px;
    `
  )}

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      height: 400px;
    `
  )}

  ${breakpointUp(
    BREAKPOINTS.XL,
    css`
      min-height: 500px;
      max-height: 100%;
      height: 100%;
    `
  )}
`;

export const StyledLinkWrapper = styled(Link)`
  position: absolute;
  bottom: ${spacer(100)};
  right: ${spacer(100)};
  display: none !important;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: inherit !important;
    `
  )}
`;

export const StyledNavigationOverlay = styled.div`
  display: none;
  height: 100%;

  & > * {
    height: 100%;
  }
  ${breakpointUp(
    BREAKPOINTS.XL,
    css`
      display: unset;
    `
  )}
`;

export const StyledCMSBannerCarouselComponent = styled.div`
  position: relative;
  height: 180px;

  & > * {
    height: 100%;
  }

  ${bannerHeight}
`;

export const StyledSliderNavigation = styled.div`
  display: none;
  align-items: center;
  gap: ${spacer(150)};
  padding: 0px ${spacer(150)};
  box-shadow: ${variable('long-box-shadow')};
  border-radius: ${variable('border-radius')};
  height: 10%;
  min-height: 40px;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
    `
  )}
`;

export const StyledSliderWrapper = styled.div`
  border-radius: ${variable('border-radius')};
  box-shadow: ${variable('box-shadow')};
  height: 100%;

  ${bannerHeight}
`;

export const StyledSliderEmbla = styled(SliderEmbla)`
  border-radius: ${variable('border-radius')};
  height: 100%;

  ${breakpointUp(
    BREAKPOINTS.XL,
    css`
      height: 90%;
    `
  )}

  .embla,
  .embla__viewport,
  .embla__container {
    height: 100%;
  }

  .embla__viewport {
    border-radius: ${variable('border-radius')};
  }
`;
