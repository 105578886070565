import { CMSButton } from 'types/CMS';
import SearchProductSlider from '../../../../Product/SearchProductSlider/SearchProductSlider';

type CMSProductSliderComponentProps = {
  button?: CMSButton;
  query?: string;
  title?: string;
};

const CMSProductSliderComponent = ({ button, query, title }: CMSProductSliderComponentProps) => {
  if (!query || !title) {
    return null;
  }

  return (
    <div className="container">
      <div className="c-masonry__item c-masonry--4x1 products-slider">
        <SearchProductSlider button={button} query={query} title={title} />
      </div>
    </div>
  );
};

export default CMSProductSliderComponent;
