import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import { breakpointUp } from '../../../../../theme';

type StyledProps = { desktopOnly: boolean };
export const StyledMasonryTileData = styled.div<StyledProps>`
  ${({ desktopOnly }) =>
    desktopOnly &&
    css`
      display: none;

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          display: block;
        `,
      )};
    `}
`;
