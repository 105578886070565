import styled from 'styled-components';
import { variable, color, spacer } from 'theme';
import Box from '../Box/Box';
import Typography from '../Typography/Typography';

export const StyledCategoryName = styled(Typography)`
  color: ${color('primary-400')};
`;

export const CategoryTileWrapper = styled(Box)`
  ${variable('border')}
  border-radius: ${variable('border-radius-long')};
  padding: ${spacer(100)};

  &:hover ${StyledCategoryName} {
    color: ${color('primary-300')};
  }
`;

export const CategoryTileImageWrapper = styled(Box)`
  padding: ${spacer(100)};
`;
