import { CMSButton } from 'types/CMS';
import config from '../../../../../../../config';
import { BRAND_VALUES } from '../../../../../../../constants/brand';
import { ICON_NAMES } from '../../../../../../../constants/icon';
import Button from '../../../../../../Button/Button';
import Icon from '../../../../../../Icon/Icon';
import Link from '../../../../../../Link/Link';
import Typography from '../../../../../../Typography/Typography';

const { platform } = config;

type CMSContentBlockStorefinderTemplateProps = {
  button?: CMSButton;
  index: number;
  text?: string[];
  title?: string;
};

const CMSContentBlockStorefinderTemplate = ({
  button,
  index,
  text = [],
  title,
}: CMSContentBlockStorefinderTemplateProps) => (
  <div className={`${index % 2 === 0 ? 'left-part' : 'right-part'} part with-image orange vertical-align`}>
    <div className="content-wrap">
      <div className="image-wrap">
        <Icon
          className="store-icon"
          type={platform.toLowerCase() === BRAND_VALUES.KREFEL.toLowerCase() ? 'custom' : 'fa'} // Use Krefel Pickup Instore icon if platform is Krefel
          name={ICON_NAMES.STORE}
          size={400}
        />
      </div>
      <div className="text-wrap">
        <Typography fontWeight="normal" tag="h3" type="h1">
          {title}
        </Typography>

        {text?.map((line, lineIndex) => (
          <p key={lineIndex} className="radikal text-large" dangerouslySetInnerHTML={{ __html: line }} />
        ))}

        {button?.url && (
          <Link className="storefinder-link-wrapper" newTab={button.isExternal} url={button.url}>
            <Button color="secondary">{button.text}</Button>
          </Link>
        )}
      </div>
    </div>
  </div>
);

export default CMSContentBlockStorefinderTemplate;
