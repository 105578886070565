// @ts-nocheck
import useRouter from 'hooks/useRouter';
import { useMemo } from 'react';
import { CMSComponent } from 'types/CMS';
import { CMS_COMPONENT_TYPES, CMS_COMPONENT_TYPE_CODES, SLOT_TEMPLATES } from '../../../constants/cms';
import CMSComponents from './components';

interface CMSComponentProps {
  cmsComponent: CMSComponent;
  index: number;
  slotTemplate?: string;
  webExclusiveThreshold?: number;
}

const CMSComponent = ({ cmsComponent, index, slotTemplate, webExclusiveThreshold }: CMSComponentProps) => {
  const { locale } = useRouter();

  const withCmsScriptEval = !!cmsComponent?.enableCmsScriptEval;

  const Template = useMemo(() => {
    if (cmsComponent?.type === CMS_COMPONENT_TYPES.CMS_PRODUCTS_GROUP_DATA && slotTemplate === SLOT_TEMPLATES.MASONRY) {
      return CMSComponents.cmsMasonryProductTileWsDTO;
    }

    if (cmsComponent?.typeCode === CMS_COMPONENT_TYPE_CODES.CMS_PARAGRAPH_COMPONENT) {
      return CMSComponents.cmsParagraphComponentWsDTO;
    }

    return CMSComponents[cmsComponent?.type];
  }, [cmsComponent, slotTemplate]);

  if (Template) {
    return (
      <Template
        index={index}
        locale={locale}
        webExclusiveThreshold={webExclusiveThreshold}
        withScriptEval={withCmsScriptEval}
        {...cmsComponent}
      />
    );
  }

  return null;
};

export default CMSComponent;
