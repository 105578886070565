import styled, { css } from 'styled-components';
import { spacer } from 'theme';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import { breakpointDown, breakpointUp, color, variable } from '../../../../../theme/functions';
import Box from '../../../../Box/Box';

export const CmsLeaderBoardComponentWrapper = styled(Box)`
  &.masonry-leader-board {
    padding-top: ${spacer(150)};
  }

  .slick-div {
    padding: 0px ${spacer(25)};

    &.first {
      padding-left: 0px;
    }

    &.last {
      padding-right: 0px;
    }
  }

  .leader-board-wrapper {
    position: relative;
    height: 200px;
    cursor: pointer;

    ${breakpointDown(
      BREAKPOINTS.SM,
      css`
        margin-top: var(--spacer-200);
        margin-bottom: var(--spacer-200);
      `
    )};

    ${breakpointUp(
      BREAKPOINTS.SM,
      css`
        height: 150px;
      `
    )};

    .leader-board-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 100%;
      padding: ${spacer(100)};
      align-items: flex-end;

      .leader-board-copy {
        display: flex;
        flex-direction: column;
        gap: ${spacer(50)};

        ${breakpointDown(
          BREAKPOINTS.SM,
          css`
            align-self: flex-start;
          `
        )};

        .leader-board-title,
        .leader-board-subtitle {
          padding: ${spacer(25)} ${spacer(50)};
          width: fit-content;

          ${breakpointUp(
            BREAKPOINTS.SM,
            css`
              line-height: ${spacer(200)};
            `
          )};
        }

        .leader-board-title {
          background-color: ${color('primary-300')};
        }

        .leader-board-subtitle {
          background-color: ${color('secondary-300')};
        }
      }

      .leader-board-button {
        position: absolute;
        padding: ${spacer(50)};
        right: ${spacer(100)};
        bottom: ${spacer(100)};
        border-radius: ${variable('border-radius')};
      }
    }
  }
`;
