/**
 * Scrolling utility class
 *
 * @author janssco
 * @since 1.0
 */

import { MAIN_BAR_CONTAINER_ID } from 'constants/components';
import { MouseEvent } from 'react';

/**
 * ScrollTo function used to make an anchor tag scroll to the specified target element id
 * @param {object} event - Default anchor event
 * @param {object} targetElement - ID of the element we have to scroll to
 * @returns {void} Scrolls user to desired element
 */
export const scrollTo = (event?: MouseEvent, targetElement?: string, offset?: number, behavior?: ScrollBehavior) => {
  event?.preventDefault();

  const headerOffset = offset || (document?.getElementById(MAIN_BAR_CONTAINER_ID)?.clientHeight ?? 0) + 50;

  if (typeof window !== 'undefined' && targetElement) {
    const element = document.getElementById(targetElement);

    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = headerOffset ? elementPosition - headerOffset : elementPosition;

      window.scrollBy({
        behavior,
        top: offsetPosition,
      });
    }
  }
};
