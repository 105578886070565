import React from 'react';
import { StyledNavListItem } from './NavListItem.styled';

interface NavListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  listStyleType?: string;
  onClick?: () => void;
  shouldScrollToTop?: boolean;
}

const NavListItem = ({
  active = false,
  children,
  className,
  listStyleType,
  onClick,
  shouldScrollToTop = false,
  ...props
}: NavListItemProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }
  };

  const mergedClassName = `${className} ${active && 'active'}`;

  return (
    <StyledNavListItem {...props} className={mergedClassName} listStyleType={listStyleType}>
      <div onClick={handleClick} role="button" tabIndex={0}>
        {children}
      </div>
    </StyledNavListItem>
  );
};

export default NavListItem;
