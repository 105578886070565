import { FaqItem } from 'types/Faq';

/**
 * Creates a slug from the FAQ question.
 *
 * @param {object} faqItem - The FAQ item.
 * @returns {string} The question slug.
 */
export const getFaqQuestionSlug = (faqItem: FaqItem): string | null => {
  if (faqItem.url) {
    const arr = faqItem.url.split('/');

    if (arr.length > 1) {
      return arr[1];
    }
  }

  return null;
};

export const preRenderCategoryPaths = (paths: Array<string>) => {
  const result = paths.reduce(
    (acc, path) => {
      const splitPath = path.split('/');
      const locale = splitPath[1];
      const slug = splitPath.splice(3, splitPath.length);

      if (slug && slug.length > 0) {
        acc.push({
          locale,
          params: { slug: [...slug] },
        });
      }

      return acc;
    },
    [] as { locale: string; params: { slug: string[] } }[],
  );

  return result;
};

export const preRenderProductPaths = (paths: string[]) => {
  if (!paths) {
    return [];
  }

  const result = paths.reduce(
    (acc, path) => {
      const splittedPath = path.split('/');
      const locale = splittedPath?.[1];
      const id = splittedPath?.[3]?.split('-')?.[0].toString();

      if (id) {
        acc.push({
          locale,
          params: { id },
        });
      }

      return acc;
    },
    [] as { locale: string; params: { id: string } }[],
  );

  return result;
};
